import {
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controller } from "react-hook-form";
import _, { debounce } from "lodash";
import EmojiPopover from "../EmojiPopover/EmojiPopover";
import { Polly } from "aws-sdk";
import { useCallback, useEffect, useState } from "react";

export const BotName = (props) => {
  const { control, botName, setBotName, orgName } = props;
  return (
    <Controller
      control={control}
      name="botName"
      defaultValue={!_.isNil(botName) ? botName : ""}
      rules={{
        required: {
          value: true,
          message: "Bot Name is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          margin="normal"
          label="Bot Name"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const name = e.target.value;

            onChange(name);
            setBotName(name);
          }}
          fullWidth
          value={botName}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          placeholder={`${orgName}'s Bot`}
        />
      )}
    />
  );
};

export const BotVoice = (props) => {
  const { control, botVoice, setBotVoice } = props;

  return (
    <Controller
      control={control}
      name="botVoice"
      defaultValue={botVoice ? botVoice : "Matthew"}
      rules={{
        required: {
          value: true,
          message: "Bot Voice is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <FormControl margin="normal" fullWidth error={error !== undefined}>
          <InputLabel id="bot-voice-label" shrink>
            Bot Voice
          </InputLabel>
          <Select
            notched
            labelId="bot-voice-label"
            id="demo-simple-select"
            value={botVoice ? botVoice : "Matthew"}
            label="Bot Voice"
            onChange={(e) => {
              console.log("voice: ", e.target.value);
              const voice = e.target.value;
              onChange(voice);
              setBotVoice(voice);
            }}
          >
            <MenuItem value={"Matthew"}>Male</MenuItem>
            <MenuItem value={"Joanna"}>Female</MenuItem>
          </Select>
          <FormHelperText>{error ? error.message : ""}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

export const VoiceGreeting = (props) => {
  const { control, voiceGreeting, setVoiceGreeting, orgName, voiceId } = props;
  const [audioSrc, setAudioSrc] = useState("");

  // Initialize Amazon Polly client outside of the debounced function
  // Make sure to handle credentials securely, consider using environment variables
  const polly = new Polly({
    region: "us-west-2",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  // Synthesize speech function
  const synthesizeSpeech = async (inputText) => {
    const params = {
      OutputFormat: "mp3",
      Text: inputText,
      VoiceId: voiceId || "Matthew",
      TextType: "text",
    };

    try {
      const { AudioStream } = await polly.synthesizeSpeech(params).promise();
      const audioUrl = URL.createObjectURL(
        new Blob([AudioStream], { type: "audio/mp3" })
      );
      setAudioSrc(audioUrl);
    } catch (error) {
      console.error("An error occurred while synthesizing the speech:", error);
    }
  };

  // Memoized debounced function
  const debouncedSynthesizeSpeech = useCallback(
    debounce((inputText) => {
      synthesizeSpeech(inputText);
    }, 500),
    []
  );

  useEffect(() => {
    if (voiceGreeting) {
      debouncedSynthesizeSpeech(voiceGreeting);
    }
  }, [voiceGreeting]);

  useEffect(() => {
    synthesizeSpeech(voiceGreeting);
  }, [voiceId]);

  return (
    <Controller
      control={control}
      name="voiceGreeting"
      defaultValue={!_.isNil(voiceGreeting) ? voiceGreeting : ""}
      rules={{
        required: {
          value: true,
          message: "Voice greeting is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <>
          <TextField
            margin="normal"
            label="Voice Greeting"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              const greeting = e.target.value;

              onChange(greeting);
              setVoiceGreeting(greeting);
            }}
            value={voiceGreeting}
            fullWidth
            multiline
            rows={2}
            error={error !== undefined}
            helperText={error ? error.message : ""}
            placeholder={`Thanks for calling ${orgName}`}
          />

          <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {audioSrc && <audio src={audioSrc} controls />}
          </div>
        </>
      )}
    />
  );
};

export const SmsGreeting = (props) => {
  const { control, smsGreeting, setSmsGreeting, orgName } = props;

  return (
    <Controller
      control={control}
      name="smsGreeting"
      defaultValue={!_.isNil(smsGreeting) ? smsGreeting : ""}
      rules={{
        required: {
          value: true,
          message: "SMS greeting is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          margin="normal"
          label="SMS Greeting"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const greeting = e.target.value;

            onChange(greeting);
            setSmsGreeting(greeting);
          }}
          value={smsGreeting}
          multiline
          fullWidth
          rows={2}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          placeholder={`Thanks for calling ${orgName}`}
        />
      )}
    />
  );
};

export const SmsLink = (props) => {
  const {
    control,
    linkKey,
    linkValue,
    setLinkValue,
    includeLink,
    naturalName,
  } = props;

  return (
    <div
      style={{
        display: "flex",
        columnGap: "1em",
        alignItems: "center",
      }}
    >
      <Controller
        control={control}
        name={linkKey}
        defaultValue={!_.isNil(linkValue) ? linkValue : ""}
        rules={{
          required: {
            value: true,
            message: `${naturalName} is required`,
          },
        }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <TextField
            margin="normal"
            label={naturalName}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              const link = e.target.value;

              onChange(link);
              setLinkValue(link);
            }}
            value={linkValue}
            fullWidth
            error={error !== undefined}
            helperText={error ? error.message : ""}
          />
        )}
      />
      <EmojiPopover
        textBody={linkValue}
        setTextBody={(text) => {
          setLinkValue(text);
        }}
      />

      <IconButton
        style={{ width: "2em", height: "2em" }}
        aria-label="delete"
        onClick={() => {
          setLinkValue("");
          includeLink(false);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
