import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Polly } from "aws-sdk";
import { debounce } from "lodash";

const TextToSpeechSample = (props) => {
  const { voiceId } = props;
  const [text, setText] = useState("Thanks for using Virtual Contact AI");
  const [audioSrc, setAudioSrc] = useState("");

  // Initialize Amazon Polly client outside of the debounced function
  // Make sure to handle credentials securely, consider using environment variables
  const polly = new Polly({
    region: "us-west-2",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  });

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  // Synthesize speech function
  const synthesizeSpeech = async (inputText) => {
    const params = {
      OutputFormat: "mp3",
      Text: inputText,
      VoiceId: voiceId || "Matthew",
      TextType: "text",
    };

    try {
      const { AudioStream } = await polly.synthesizeSpeech(params).promise();
      const audioUrl = URL.createObjectURL(
        new Blob([AudioStream], { type: "audio/mp3" })
      );
      setAudioSrc(audioUrl);
    } catch (error) {
      console.error("An error occurred while synthesizing the speech:", error);
    }
  };

  // Memoized debounced function
  const debouncedSynthesizeSpeech = useCallback(
    debounce((inputText) => {
      synthesizeSpeech(inputText);
    }, 500),
    []
  );

  useEffect(() => {
    if (text) {
      debouncedSynthesizeSpeech(text);
    }
  }, [text]);

  useEffect(() => {
    synthesizeSpeech(text);
  }, [voiceId]);

  return (
    <div>
      <TextField
        label="Sample Text"
        variant="outlined"
        value={text}
        onChange={handleTextChange}
        fullWidth
        margin="normal"
      />
      <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
        {audioSrc && <audio src={audioSrc} controls />}
      </div>
    </div>
  );
};

export default TextToSpeechSample;
