import { storeToken, revokeToken, JWT } from "../../config/contants";

import {
  credentialsLogin,
  credentialsSignup,
  googleSignup,
  msftSignup,
  getAccount,
  activateAccount,
  forgotPasswordInit,
  forgotPasswordVerify,
  resetPassword,
  logout,
  sso,
  sendSMSCode,
  verifySMSCode,
  changeProfilePicture,
  updateAccount,
  completeAccountSetup,
  changePassword,
  mfaVerifySMSCode,
  resendActivationEmail,
  addSubscriptionAuthority,
  refresh,
} from "./accountApi";

import { persistor } from "../../models/store";

import {
  setAccessToken,
  signupSuccess,
  logoutSuccess,
  forgotPasswordVerifySuccess,
  forgotPasswordInitSuccess,
  getAccountSuccess,
  disconnectStomp,
  clearRedux,
  sendSMSCodeSuccess,
  verifySMSCodeSuccess,
  changeProfilePictureSuccess,
  updateAccountSuccess,
  accountSetupPersonalInfoSuccess,
  accountSetupVerifySMSSuccess,
  accountSetupCreateOrganizationSuccess,
  completeAccountSetupSuccess,
  changePasswordSuccess,
  accountSetupChangePasswordSuccess,
  activateAccountSuccess,
  resendActivationEmailSuccess,
  addSubscriptionAuthoritySuccess,
} from "./accountReducer";

import _ from "lodash";
import {
  failure,
  loading,
  setRedirection,
  toastFailure,
  toastSuccess,
} from "../uiReducer";
import { useSelector } from "react-redux";
import {
  createOrganization,
  createOrganizationPlace,
  updateOrganization,
} from "../organization/organizationApi";
import storage from "redux-persist/lib/storage";

export const credentialsLoginAction = (email, password) => async (dispatch) => {
  console.log("handle credentials login");
  dispatch(loading(true));

  try {
    const response = await credentialsLogin(email, password);
    const { id_token } = response.data;
    const jwt = `Bearer ${id_token}`;

    console.log(`jwt: ${jwt}`);
    // store token to local storage
    await storeToken(JWT, jwt);
    // store the token in redux state
    dispatch(setAccessToken(jwt));
    // get the logged in user's account info
    dispatch(getAccountAction());
    dispatch(handleRedirectAction(response));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const credentialsSignupAction = (user) => async (dispatch) => {
  console.log("handle credentials sign up");
  dispatch(loading(true));

  try {
    const response = await credentialsSignup(user);
    console.log("credentials signup response: ", response);
    const { data } = response;
    console.log(data);
    // show the account activation screen
    dispatch(signupSuccess(data));
    dispatch(setRedirection("/verify-email"));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const ssoLoginAction = (token) => async (dispatch) => {
  console.log("handle sso login");
  dispatch(loading(true));

  try {
    // store google token to local storage
    const response = await sso(token);

    const { id_token } = response.data;
    const jwt = `Bearer ${id_token}`;

    // store API token to local storage
    await storeToken(JWT, jwt);
    // store the token in redux state

    console.log("before dispatch");
    await dispatch(setAccessToken(jwt));
    console.log("after dispatch");
    dispatch(getAccountAction());
    dispatch(handleRedirectAction(response));

    // get the logged in user's account info
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    if (error.response) {
      dispatch(failure(error.response?.data?.message));
    } else {
      dispatch(failure(error));
    }
  }
  dispatch(loading(false));
};

export const googleSignupAction = (token) => async (dispatch) => {
  console.log("handle google sign up");
  dispatch(loading(true));

  try {
    const response = await googleSignup(token);
    const { id_token } = response.data;
    const jwt = `Bearer ${id_token}`;

    // store token to local storage
    await storeToken(JWT, jwt);
    // store the token in redux state
    await dispatch(setAccessToken(jwt));
    // get the logged in user's account info
    dispatch(getAccountAction());
    dispatch(handleRedirectAction(response));
    dispatch(loading(false));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error));
  }
  dispatch(loading(false));
};

export const msftSignupAction = (tokenResponse) => async (dispatch) => {
  console.log("handle msft sign up");
  dispatch(loading(true));

  try {
    // Log in to get an authentication token
    console.log("auth-state: ", tokenResponse);

    // Send the id token to the backend
    const idToken = tokenResponse.params["id_token"];
    console.log("id token: ", idToken);

    const response = await msftSignup(idToken);
    console.log(response);
    const { authorization } = response.headers;

    // store token to local storage
    await storeToken(JWT, authorization);
    // store the token in redux state
    await dispatch(setAccessToken(authorization));
    // get the logged in user's account info
    dispatch(getAccountAction());
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error));
  }
  dispatch(loading(false));
};

export const getAccountAction = () => async (dispatch) => {
  console.log("handle get user account");
  dispatch(loading(true));

  try {
    const jwt = sessionStorage.getItem(JWT);
    console.log("JWT: ", jwt);
    const response = await getAccount(jwt);
    const { data } = response;

    console.log("full account response data: ", data);

    await dispatch(getAccountSuccess(data));
    persistor.persist();
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const updateAccountAction = (account) => async (dispatch) => {
  console.log("updating with account: ", account);

  dispatch(loading(true));

  try {
    const response = await updateAccount(account);
    const { data } = response;

    console.log("response: ", data);

    dispatch(updateAccountSuccess(data));
    toastSuccess("Success!", "Your account was successfully updated.");
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const accountSetupPersonalInfoAction = (account) => async (dispatch) => {
  console.log("account setup personal info: ", account);

  dispatch(loading(true));

  try {
    const updateAccountResponse = await updateAccount(account);
    const data = updateAccountResponse.data;

    await dispatch(accountSetupPersonalInfoSuccess(data));
    await dispatch(completeAccountSetupAction());
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const refreshToken = () => async (dispatch) => {
  console.log("handle refresh");
  dispatch(loading(true));

  try {
    const response = await refresh();
    const { id_token } = response.data;
    const jwt = `Bearer ${id_token}`;

    console.log(`jwt: ${jwt}`);
    // store token to local storage
    await storeToken(JWT, jwt);
    // store the token in redux state
    await dispatch(setAccessToken(jwt));
    console.log("the token was refreshed");
  } catch (error) {
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const accountSetupVerifySMSAction =
  ({ code, phoneNumber }) =>
  async (dispatch) => {
    console.log(
      `handle verify verification code: ${code} for number ${phoneNumber}`
    );

    dispatch(loading(true));

    try {
      const response = await verifySMSCode(code, phoneNumber);
      const { data } = response;

      dispatch(accountSetupVerifySMSSuccess(data));
    } catch (error) {
      toastFailure(error);
      dispatch(loading(false));

      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const accountSetupCreateOrganizationAction =
  (organizationInfo, setDefault) => async (dispatch) => {
    console.log("handle account setup create organization ", organizationInfo);

    dispatch(loading(true));

    try {
      const response = await createOrganizationPlace(
        organizationInfo,
        setDefault
      );
      const { data } = response;

      dispatch(accountSetupCreateOrganizationSuccess(data));
    } catch (error) {
      toastFailure(error);
      dispatch(loading(false));

      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const sendSMSVerificationCode = (phoneNumber) => async (dispatch) => {
  console.log(
    "handle send SMS verification code to provided phone number: ",
    phoneNumber
  );
  dispatch(loading(true));

  try {
    const response = await sendSMSCode(phoneNumber);
    const { data } = response;
    dispatch(sendSMSCodeSuccess(data));
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const verifySMSVerificationCode =
  ({ code, phoneNumber }) =>
  async (dispatch) => {
    console.log(
      `handle verify verification code: ${code} for number ${phoneNumber}`
    );
    dispatch(loading(true));

    try {
      const response = await verifySMSCode(code, phoneNumber);
      const { data } = response;
      dispatch(verifySMSCodeSuccess(data));
      dispatch(handleRedirectAction(response));
    } catch (error) {
      toastFailure(error);
      dispatch(loading(false));

      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const mfaVerifySMSVerificationCode =
  ({ code, phoneNumber }) =>
  async (dispatch) => {
    console.log(
      `handle mfa verify verification code: ${code} for number ${phoneNumber}`
    );
    dispatch(loading(true));

    try {
      const response = await mfaVerifySMSCode(code, phoneNumber);
      // get the logged in user's account info
      await dispatch(getAccountAction());
      const { id_token } = response.data;
      const jwt = `Bearer ${id_token}`;

      console.log(`jwt: ${jwt}`);
      // store token to local storage
      await storeToken(JWT, jwt);
      // store the token in redux state
      dispatch(setAccessToken(jwt));
      dispatch(handleRedirectAction(response));
    } catch (error) {
      toastFailure(error);
      dispatch(loading(false));

      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const logoutAction = () => async (dispatch) => {
  console.log("handle logout");
  dispatch(loading(true));

  try {
    // log out of the API
    const jwt = sessionStorage.getItem(JWT);
    console.log("JWT: ", jwt);
    await logout(jwt);
    // remove tokens from local storage
    await revokeToken(JWT);
    await dispatch(disconnectStomp());
    persistor.purge();
    dispatch(logoutSuccess());
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error.response.data.message));
  }
  dispatch(loading(false));
};

export const resendActivationEmailAction = (email) => async (dispatch) => {
  dispatch(loading(true));

  try {
    const response = await resendActivationEmail(email);
    console.log("resend activation email ", response);
    const { data } = response;
    console.log(data);
    dispatch(resendActivationEmailSuccess());
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const activateAccountAction = (activationKey) => async (dispatch) => {
  console.log("handle activate account");
  dispatch(loading(true));

  try {
    console.log("activation key: ", activationKey);
    const response = await activateAccount(activationKey);

    console.log("credentials signup response: ", response);
    const { data } = response;
    console.log(data);
    dispatch(activateAccountSuccess(data));
    toastSuccess("Success!", "Your account was successfully activated.");
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error.response.data.message));
  }
  dispatch(loading(false));
};

export const completeAccountSetupAction = () => async (dispatch) => {
  console.log("handle complete account setup action");
  dispatch(loading(true));

  try {
    const response = await completeAccountSetup();
    const { data } = response;
    dispatch(completeAccountSetupSuccess(data));
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error.response.data.message));
  }
  dispatch(loading(false));
};

export const accountSetupChangePasswordAction =
  (changePasswordDTO) => async (dispatch) => {
    console.log("handle account setup change password action");
    dispatch(loading(true));

    try {
      const response = await changePassword(changePasswordDTO);

      console.log("change password response: ", response);
      const { data } = response;
      dispatch(accountSetupChangePasswordSuccess(data));
    } catch (error) {
      toastFailure(error);
      dispatch(loading(false));

      dispatch(failure(error.response.data.message));
    }
    dispatch(loading(false));
  };

export const changePasswordAction = (changePasswordDTO) => async (dispatch) => {
  console.log("handle change password action");
  dispatch(loading(true));

  try {
    const response = await changePassword(changePasswordDTO);
    const { data } = response.data;
    dispatch(changePasswordSuccess(data));
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    dispatch(failure(error.response.data.message));
  }
  dispatch(loading(false));
};

export const forgotPasswordInitAction = (email) => async (dispatch) => {
  dispatch(loading(true));

  try {
    const response = await forgotPasswordInit(email);
    dispatch(forgotPasswordInitSuccess(email));
    dispatch(loading(false));
    return Promise.resolve(response); // Explicitly resolve the promise on success
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    if (error.response) {
      dispatch(failure(error.response?.data?.message));
    } else {
      dispatch(failure(error));
    }
    return Promise.reject(error); // Explicitly reject the promise on failure
  }
};

export const forgotPasswordVerifyAction =
  (resetKey, email) => async (dispatch) => {
    console.log("handle forgot password verify");
    dispatch(loading(true));

    try {
      console.log("RESET KEY: ", resetKey);
      const response = await forgotPasswordVerify({
        key: resetKey,
        email: email,
      });
      // redirect to the password reset screen
      dispatch(forgotPasswordVerifySuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(loading(false));

      if (error.response) {
        dispatch(failure(error.response?.data?.message));
      } else {
        dispatch(failure(error));
      }
    }
    dispatch(loading(false));
  };

export const resetPasswordAction = (resetPasswordData) => async (dispatch) => {
  console.log("handle reset password");
  dispatch(loading(true));

  try {
    const response = await resetPassword(resetPasswordData);
    console.log(response);
    toastSuccess("Success!", "Your password was successfully updated.");
  } catch (error) {
    toastFailure(error);
    dispatch(loading(false));

    if (error.response) {
      dispatch(failure(error.response?.data?.message));
    } else {
      dispatch(failure(error));
    }
  }
  dispatch(loading(false));
};

export const changeProfilePictureAction = (photo) => async (dispatch) => {
  console.log("handle change profile picture: ", photo);
  dispatch(loading(true));

  try {
    console.log("handle change profile picture");
    const response = await changeProfilePicture(photo);

    console.log("the user response data was: ", response.data);
    dispatch(changeProfilePictureSuccess(response.data));
  } catch (error) {
    toastFailure(error);

    if (error.response) {
      dispatch(failure(error.response?.data?.message));
    } else {
      dispatch(failure(error));
    }
  }
  dispatch(loading(false));
};

export const addSubscriptionAuthorityAction =
  (sessionId) => async (dispatch) => {
    console.log("handle add subscription authority: ", sessionId);
    dispatch(loading(true));

    try {
      const response = await addSubscriptionAuthority(sessionId);

      console.log("the user response data was: ", response.data);
      dispatch(addSubscriptionAuthoritySuccess(response.data));
    } catch (error) {
      toastFailure(error);

      if (error.response) {
        dispatch(failure(error.response?.data?.message));
      } else {
        dispatch(failure(error));
      }
    }
    dispatch(loading(false));
  };

export const handleRedirectAction = (response) => async (dispatch) => {
  const redirectTo = response.headers["x-target-url"]; // Get target URL from the header
  console.log("recieved redirectTo: ", redirectTo);
  console.log("recieved response: ", response);

  dispatch(setRedirection(redirectTo));
};
