import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Button, Container, Link } from "@mui/material";

import { makeStyles } from "@mui/styles";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import logo from "../../shared/images/ls-logo-png.png";
import { PinPad } from "../../components/Form/Form";

import {
  mfaVerifySMSVerificationCode,
  sendSMSVerificationCode,
} from "../../models/account/accountActions";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import useRedirect from "../../shared/useRedirect";
import backgroundImage from "../../shared/images/login-bg.png";

const PinPadVerificationScreen = (props) => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const [pins, setPins] = useState("");

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errors }, // here
  } = useForm();

  useEffect(() => {
    const phoneNumber = account?.account?.phoneNumber;
    if (phoneNumber) {
      console.log("send code...");

      handleSendCode();
    }
  }, [account?.account?.phoneNumber]); // Dependency on just the phoneNumber

  const handleSendCode = () => {
    console.log("send code to ", account?.account?.phoneNumber);
    dispatch(sendSMSVerificationCode(account?.account?.phoneNumber));
  };

  const handleMfaVerifySMS = () => {
    console.log("verify code...");

    dispatch(
      mfaVerifySMSVerificationCode({
        code: pins.join(""),
        phoneNumber: account?.account?.phoneNumber,
      })
    );
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useRedirect();

  const modalStyle = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "400px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        margin: 0,
        padding: 0,
        overflow: "hidden",
      }}
    >
      <Box sx={modalStyle}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "1em",
              rowGap: "0",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "1000px",
              }}
            >
              <FingerprintIcon
                style={{
                  height: "40px",
                  width: "40px",
                  color: "white",
                }}
              />
            </div>
            <h2 style={{ margin: isMobile ? "1em 0 0 0" : "0em" }}>
              Two Factor Authentication
            </h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              rowGap: "1em",
              height: "300px",
            }}
          >
            <p style={{ margin: "0px" }}>
              We sent a 6 digit verification code to your device
            </p>
            {/* Assuming PinPad is another custom component */}
            <PinPad
              pinLength={6}
              pins={pins}
              setPins={setPins}
              control={control}
              errors={errors}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: isMobile ? "column" : "row",
                columnGap: isMobile ? "0" : "1em",
                rowGap: isMobile ? "1em" : "0",
              }}
            >
              <Button
                onClick={handleSendCode}
                style={{ width: isMobile ? "100%" : "auto" }}
              >
                Send New Code
              </Button>
              <Button
                disabled={!isDirty || !isValid}
                variant="outlined"
                onClick={handleSubmit(handleMfaVerifySMS)}
                style={{ width: isMobile ? "100%" : "auto" }}
              >
                Verify
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Container>
  );
};

export default PinPadVerificationScreen;
