import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Paper,
  InputAdornment,
  IconButton,
  Link,
  Divider,
  CircularProgress,
} from "@mui/material";

import {
  Visibility,
  VisibilityOff,
  LockOutlined as LockIcon,
  EmailOutlined as EmailIcon,
} from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";

import { GoogleLogin } from "@react-oauth/google";
import {
  credentialsSignupAction,
  googleSignupAction,
} from "../../models/account/accountActions";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
import logo from "../../shared/images/vcai-logo2.png";
import backgroundImage from "../../shared/images/login-bg.png";
import useRedirect from "../../shared/useRedirect";
import {
  CreatePassword,
  Email,
  FirstName,
  LastName,
} from "../../components/Form/Form";

const SignupScreen = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm();

  const [user, setUser] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const ui = useSelector((state) => state.ui);

  const onVerifyRecaptcha = useCallback((token) => {
    setRecaptchaToken(token);
  });

  const handleCredentialsSignup = () => {
    dispatch(credentialsSignupAction(user));
  };

  const responseGoogle = (response) => {
    dispatch(googleSignupAction(response.credential));
  };

  useRedirect();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [recaptchaToken, setRecaptchaToken] = useState("");

  return (
    <>
      {ui.loading && (
        <div
          style={{
            backgroundColor: "black",
            opacity: "0.5",
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </div>
      )}

      <Container
        maxWidth={false}
        sx={{
          height: isMobile ? "auto" : "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100vw",
          margin: 0,
          padding: 0,
        }}
      >
        <Paper
          elevation={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 4,
            margin: "1em",
            borderRadius: 0,
            maxHeight: isMobile ? "none" : "850px",
            maxWidth: { md: "500px" },
            minWidth: { md: "500px" },
            backgroundColor: "rgba(255, 255, 255, 0.85)",
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Company Logo"
            sx={{
              height: "100px",
              mb: 2,
            }}
          />
          <Typography
            variant="h5"
            component="h1"
            sx={{ mb: 3, display: "flex", alignItems: "center" }}
          >
            Create Account
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(handleCredentialsSignup)}
            noValidate
            sx={{ width: "100%" }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                columnGap: "1em",
                flexDirection: "row",
              }}
            >
              <FirstName
                control={control}
                firstName={user?.firstName}
                setFirstName={(firstName) => {
                  setUser({ ...user, firstName: firstName });
                }}
              />

              <LastName
                control={control}
                lastName={user?.lastName}
                setLastName={(lastName) => {
                  setUser({ ...user, lastName: lastName });
                }}
              />
            </div>

            <Email
              control={control}
              email={user?.email}
              setEmail={(email) => {
                setUser({ ...user, email: email });
              }}
            />

            <CreatePassword
              row={true}
              control={control}
              password={user?.password}
              confirmPassword={confirmPassword}
              setPassword={(password) => {
                setUser({ ...user, password: password });
              }}
              setConfirmPassword={(confirmPassword) => {
                setConfirmPassword(confirmPassword);
              }}
            />

            <ReCAPTCHA
              sitekey="6LcffO4lAAAAAC3A0Hdn7Je9-u6Qi6XDotmoG2Ze"
              onChange={onVerifyRecaptcha}
              sx={{ my: 2 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!recaptchaToken}
              sx={{
                mt: 2,
                mb: 1,
                bgcolor: "primary.main",
                "&:disabled": { bgcolor: "grey.500" },
              }}
            >
              Sign Up
            </Button>
          </Box>
          <Divider sx={{ width: "100%", my: 2 }}>OR</Divider>
          <GoogleLogin
            onSuccess={responseGoogle}
            onError={() => console.log("Login Failed")}
            sx={{ mb: 2 }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Link
              href="/login"
              variant="body2"
              sx={{ fontWeight: "bold", my: 2 }}
            >
              Already have an account? Sign in
            </Link>
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="body2" color="textSecondary">
                © {moment().year()} Virtual Contact AI
              </Typography>
            </Box>
          </div>
        </Paper>
      </Container>
    </>
  );
};

export default SignupScreen;
