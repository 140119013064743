import { JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

export const createCheckoutSession = (checkoutSessionRequest) => {
  return axiosInstance.post(
    `${ROOT_URL}api/stripe/checkout/create-session`,
    checkoutSessionRequest,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const createCustomerPortalSession = () => {
  console.log(`create customer portal session`);

  return axiosInstance.post(
    `${ROOT_URL}api/stripe/customer-portal/create-session`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};
