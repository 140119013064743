import { PREMIUM_PRICE, PRO_PRICE } from "../config/contants";

export const stripePlans = [
  {
    title: "Pro",
    price: "149",
    id: PRO_PRICE,
    features: [
      "24/7 Answering",
      "Handles multiple callers simultaneously",
      "Answers common questions with natural language capabilities",
      "Customizable greeting",
      "Male and female voice options",
      "Can transfer calls to customizable number for urgent inquiries",
      "Sends SMS with online ordering link to callers",
      "Add users and admins to your account",
    ],
  },
  {
    title: "Premium",
    price: "299",
    id: PREMIUM_PRICE,
    features: [
      "Everything from PRO Plan PLUS:",
      "SMS can include multiple links (reservations, catering, directions, google reviews)",
      "Emojis included 👋 🍴 🗓️ 👨‍🍳 📍 ✉️",
      `Call analytics - track your total calls, 
      average call duration, and deflection rate`,
      `Download your call list as a CSV file - tap into the potential
      of all your callers' phone numbers`,
    ],
  },
];
