import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BotSteps from "../BotSetup/BotSteps";
import { result } from "lodash";
import emojiRegex from "emoji-regex";
import { PREMIUM } from "../../config/contants";

export const defaultSmsContent = {
  greeting: {
    emoji: "👋",
    label: "Hello, and thanks for calling our restaurant!",
  },
  links: [
    {
      id: 1,
      type: "order",
      emoji: "🍴",
      label: "Order Online: https://virtualcontactai.com/demo-ordering-page",
    },
    {
      id: 2,
      type: "reservation",
      emoji: "🗓️",
      label: "Make a Reservation: https://opentable.com",
    },
    {
      id: 3,
      type: "catering",
      emoji: "👨‍🍳",
      label: "Catering: https://ezcater.com",
    },
    {
      id: 4,
      type: "directions",
      emoji: "📍",
      label: "Directions: https://www.google.com/maps",
    },
    {
      id: 5,
      type: "review",
      emoji: "⭐",
      label: "Leave us a review: https://www.google.com/reviews",
    },
  ],
  hours: {
    type: "hours",
    emoji: "⏰",
    label: `Loading your hours directly from your Google Business Profile...`,
  },
  email: {
    type: "email",
    emoji: "📧",
    label: "Email: virtualcontactai@gmail.com",
  },
};

// Create a regex for matching emojis
const EMOJI_REGEX = emojiRegex();

export default function BotsModal(props) {
  const { open, setOpen, mode } = props;

  const organizationSlice = useSelector((state) => state.organizations);
  const organization = organizationSlice.organization;
  const botSlice = useSelector((state) => state.bot);
  const { control, handleSubmit, reset, setValue } = useForm();
  const ui = useSelector((state) => state.ui);
  const [canClose, setCanClose] = useState(false);
  const subscriptionType = organization?.subscriptionType;
  const isPremium = subscriptionType === PREMIUM;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const defaultBot = {
    voiceGreeting: `Thanks for calling ${organizationSlice?.organization?.name}`,
    botVoice: "Matthew",
    transferPhoneNumber: "",
    smsContent: defaultSmsContent,
  };

  React.useEffect(() => {
    if (botSlice.loading == false) {
      setTimeout(() => {
        setOpen(false);
        setBotInfo(defaultBot);
      }, 500);
    }
  }, [botSlice.loading]);

  React.useEffect(() => {
    if (canClose) {
      setOpen(false);
    }

    setCanClose(false);
  }, [canClose]);

  const [botInfo, setBotInfo] = useState({});

  console.log("the mode: ", mode);

  const extractEmojiAndText = (emojiTextString) => {
    // Use the regex to match emojis
    const emojiMatches = emojiTextString.match(EMOJI_REGEX);

    // Extract the emoji portion
    const emojiPortion = emojiMatches ? emojiMatches[0] : "";

    // Extract the non-emoji portion by replacing the emoji with an empty string
    const nonEmojiPortion = emojiTextString.replace(EMOJI_REGEX, "");

    result = {
      emoji: emojiPortion,
      label: nonEmojiPortion,
    };

    console.log("result: ", result);

    return result;
  };

  React.useEffect(() => {
    console.log("the mode was: ", mode);
    if (mode == "edit" && botSlice.bots.length > 0) {
      console.log("inside edit mode");
      const bot = botSlice?.bots[0];

      console.log("the bot: ", bot);

      setBotInfo({
        ...defaultBot,
        botVoice: bot.botVoice,
        voiceGreeting: bot.voiceGreeting,
        transferPhoneNumber: bot.transferPhoneNumber,
        smsContent: {
          ...defaultBot.smsContent,
          greeting: extractEmojiAndText(bot.smsGreeting),
          links: !isPremium
            ? [
                {
                  type: "order",
                  ...extractEmojiAndText(bot.onlineOrderLink),
                },
                ...defaultSmsContent.links.slice(1),
              ]
            : [
                {
                  type: "order",
                  ...extractEmojiAndText(bot.onlineOrderLink),
                },
                {
                  type: "reservation",
                  ...extractEmojiAndText(bot.reservationsLink),
                },
                {
                  type: "catering",
                  ...extractEmojiAndText(bot.cateringLink),
                },
                {
                  type: "directions",
                  ...extractEmojiAndText(bot.directions),
                },
                {
                  type: "review",
                  ...extractEmojiAndText(bot.reviewsLink),
                },
              ],
          email: {
            type: "email",
            ...extractEmojiAndText(bot.businessEmail),
          },
        },
      });
    } else {
      setBotInfo(defaultBot);
    }
  }, [mode, open, botSlice.bots]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "75%" : "550px",
    minHeight: isMobile ? 500 : 700,
    height: isMobile ? "auto" : 700,
    overflow: isMobile ? "auto" : "hidden",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setBotInfo({});
          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <BotSteps
              botInfo={botInfo}
              setBotInfo={setBotInfo}
              control={control}
              handleSubmit={handleSubmit}
              canClose={canClose}
              setCanClose={setCanClose}
              mode={mode}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
