import _ from "lodash";
import storageSession from "redux-persist/lib/storage/session";

export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";

export const GET_ORGANIZATIONS_SUCCESS = "GET_ORGANIZATIONS_SUCCESS";

export const GET_OWNED_ORGANIZATIONS_SUCCESS =
  "GET_OWNED_ORGANIZATIONS_SUCCESS";

export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";

export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";

export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";

export const REMOVE_MEMBER_SUCCESS = "REMOVE_MEMBER_SUCCESS";

export const SEND_ORGANIZATION_INVITE_SUCCESS =
  "SEND_ORGANIZATION_INVITE_SUCCESS";

export const SET_SELECTED_ORGANIZATION_MEMBER =
  "SET_SELECTED_ORGANIZATION_MEMBER";
export const UPDATE_ORGANIZATION_MEMBER_PERMISSION_SUCCESS =
  "UPDATE_ORGANIZATION_MEMBER_PERMISSION_SUCCESS";

export const GET_CURRENT_ACCOUNT_ORGANIZATION_USER_SUCCESS =
  "GET_CURRENT_ACCOUNT_ORGANIZATION_USER_SUCCESS";

export const SET_SELECTED_ORGANIZATION = "SET_SELECTED_ORGANIZATION";

export const GET_ORGANIZATION_KPIS_SUCCESS = "GET_ORGANIZATION_KPIS_SUCCESS";

export const GET_ORGANIZATION_PENDING_INVITES_SUCCESS =
  "GET_ORGANIZATION_PENDING_INVITES_SUCCESS";

export const ACCOUNT_SETUP_CREATE_ORGAIZATION_SUCCESS =
  "ACCOUNT_SETUP_CREATE_ORGAIZATION_SUCCESS";

export const SET_PLACE_DETAILS = "PLACE_DETAILS";

export const LOADING = "LOADING";

interface OrganizationKPIs {
  totalFundedAmount: number | null;
  averageFactorRate: number | null;
  defaultRate: number | null;
  totalProfit: number | null;
  totalCompletedDeals: number | null;
  totalOpenDeals: number | null;
  totalInvestors: number | null;
}

function updateObjectInArray(array: any[], idx: number, data: any) {
  return array.map((item, index) => {
    if (index !== idx) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...data,
    };
  });
}

export function setSelectedOrganization(selectedOrganization: any) {
  console.log("set selected organization", selectedOrganization);
  return {
    type: SET_SELECTED_ORGANIZATION,
    selectedOrganization: selectedOrganization,
  };
}

export function getPlaceDetailsSuccess(placeDetails: any) {
  console.log("set place details", placeDetails);
  return {
    type: SET_PLACE_DETAILS,
    placeDetails: placeDetails,
  };
}

export function getCurrentAccountOrganizationUserSuccess(
  organizationUser: any
) {
  console.log("get current account organization user success");
  return {
    type: GET_CURRENT_ACCOUNT_ORGANIZATION_USER_SUCCESS,
    organizationUser: organizationUser,
  };
}

export function updateOrganizationMemberPermissionSuccess(
  organizationUser: any
) {
  console.log("update organization member permissions success");
  return {
    type: UPDATE_ORGANIZATION_MEMBER_PERMISSION_SUCCESS,
    organizationUser: organizationUser,
  };
}

export function setSelectedOrganizationMember(organizationUser: any) {
  return {
    type: SET_SELECTED_ORGANIZATION_MEMBER,
    organizationUser: organizationUser,
  };
}

export function getOrganizationSuccess(organization: Organization) {
  return {
    type: GET_ORGANIZATION_SUCCESS,
    organization: organization,
  };
}

export function getOrganizationsSuccess(organizations: Organization[]) {
  return {
    type: GET_ORGANIZATIONS_SUCCESS,
    organizations: organizations,
  };
}

export function getOwnedOrganizationsSuccess(organizations: Organization[]) {
  return {
    type: GET_OWNED_ORGANIZATIONS_SUCCESS,
    organizations: organizations,
  };
}

export function createOrganizationSuccess(organization: Organization) {
  return {
    type: CREATE_ORGANIZATION_SUCCESS,
    organization: organization,
  };
}

export function updateOrganizationSuccess(organization: Organization) {
  return {
    type: UPDATE_ORGANIZATION_SUCCESS,
    organization: organization,
  };
}

export function sendOrganizationInviteSuccess() {
  return {
    type: SEND_ORGANIZATION_INVITE_SUCCESS,
  };
}

export function removeMemberSuccess(organization: Organization) {
  return {
    type: REMOVE_MEMBER_SUCCESS,
    organization: organization,
  };
}

export function loading(loading: boolean) {
  return {
    type: LOADING,
    loading: loading,
  };
}

export function deleteOrganizationSuccess(id: number) {
  return {
    type: DELETE_ORGANIZATION_SUCCESS,
    id: id,
  };
}

export const getOrganizationKPIsSuccess = (kpis: OrganizationKPIs) => ({
  type: GET_ORGANIZATION_KPIS_SUCCESS,
  kpis: kpis,
});

export const getOrganizationPendingInvitesSuccess = (pendingInvites: any) => ({
  type: GET_ORGANIZATION_PENDING_INVITES_SUCCESS,
  pendingInvites: pendingInvites,
});

interface Organization {
  name: string;
  email: string;
  phoneNumber: string;
  id: number | null;
  organizationUsers: any | undefined;
  owner: {
    firstName: string;
    lastName: string;
  };
  address: {
    city: string;
    state: string;
    zip: string;
    place: string;
  };
}

interface OrganizationState {
  organization: Organization;
  organizations: Organization[];
  ownedOrganizations: Organization[];
  error: string | null;
  selectedOrganizationMember: any | null;
  currentAccountOrganizationMember: any | null;
  kpis: OrganizationKPIs | {};
  pendingInvites: any[];
  placeDetails: any;
  loading: boolean;
}

const initialState: OrganizationState = {
  organization: {
    id: null,
    organizationUsers: [],
    owner: {
      firstName: "",
      lastName: "",
    },
    name: "",
    email: "",
    phoneNumber: "",
    address: {
      city: "",
      state: "",
      zip: "",
      place: "",
    },
  },
  error: null,
  organizations: [],
  ownedOrganizations: [],
  selectedOrganizationMember: null,
  currentAccountOrganizationMember: null,
  kpis: {},
  pendingInvites: [],
  placeDetails: {},
  loading: false,
};

export default (state = initialState, action: any): OrganizationState => {
  switch (action.type) {
    case GET_ORGANIZATION_SUCCESS:
      console.log("get organization success: ", action.organization);

      return {
        ...state,
        organization: { ...state.organization, ...action.organization },
      };

    case GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.organizations,
      };

    case GET_OWNED_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        ownedOrganizations: action.organizations,
      };

    case CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.organization,
        organizations: [...state.organizations, action.organization],
      };

    case LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case UPDATE_ORGANIZATION_SUCCESS:
      console.log("inside of update organization success");

      let idx = state.organizations.findIndex(
        (tm) => tm.id === action.organization.id
      );

      console.log("found organization index: ", idx);

      return {
        ...state,
        organization: action.organization,
        organizations: updateObjectInArray(
          state.organizations,
          idx,
          action.organization
        ),
      };

    case SET_SELECTED_ORGANIZATION:
      return {
        ...state,
        organization: action.selectedOrganization,
      };

    case SET_PLACE_DETAILS:
      return {
        ...state,
        placeDetails: action.placeDetails,
      };

    case SEND_ORGANIZATION_INVITE_SUCCESS:
      console.log("sent organization invite success");
      return {
        ...state,
      };

    case ACCOUNT_SETUP_CREATE_ORGAIZATION_SUCCESS:
      console.log("setting org as: ", action.org);
      return {
        ...state,
        error: null,
        organization: action.org,
      };

    case UPDATE_ORGANIZATION_MEMBER_PERMISSION_SUCCESS:
      console.log("add permission success");

      const copiedOrganizations = [...state.organizations];
      const copiedOrganization = { ...state.organization };
      const copiedOrganizationUsers = [...state.organization.organizationUsers];

      const foundUserIndex = copiedOrganizationUsers.findIndex(
        (usr) => usr.id === action.organizationUser.id
      );

      console.log("found user index: ", foundUserIndex);

      copiedOrganizationUsers[foundUserIndex] = action.organizationUser;
      copiedOrganization.organizationUsers = copiedOrganizationUsers;

      const foundOrganizationIndex = copiedOrganizations.findIndex(
        (tm) => tm.id === state.organization.id
      );

      console.log("found organization index: ", foundOrganizationIndex);

      copiedOrganizations[foundOrganizationIndex] = copiedOrganization;

      return {
        ...state,
        selectedOrganizationMember: action.organizationUser,
        organization: { ...copiedOrganization },
        organizations: [...copiedOrganizations],
      };

    case REMOVE_MEMBER_SUCCESS:
      console.log("remove member success");

      let organizationsRemoveMember = [...state.organizations];

      _.remove(
        organizationsRemoveMember,
        (organization) => organization.id === action.organization.id
      );

      return {
        ...state,
        organization: action.organization,
        organizations: organizationsRemoveMember,
      };

    case DELETE_ORGANIZATION_SUCCESS:
      let organizations = [...state.organizations];
      _.remove(organizations, (organization) => organization.id === action.id);

      return {
        ...state,
        organizations: organizations,
      };

    case SET_SELECTED_ORGANIZATION_MEMBER:
      console.log(
        "set selected organization member: ",
        action.organizationUser
      );

      return {
        ...state,
        selectedOrganizationMember: action.organizationUser,
      };

    case GET_CURRENT_ACCOUNT_ORGANIZATION_USER_SUCCESS:
      console.log("get current account organization user success");
      return {
        ...state,
        currentAccountOrganizationMember: action.organizationUser,
      };

    case GET_ORGANIZATION_KPIS_SUCCESS:
      return {
        ...state,
        kpis: action.kpis,
      };

    case GET_ORGANIZATION_PENDING_INVITES_SUCCESS:
      return {
        ...state,
        pendingInvites: action.pendingInvites,
      };

    default:
      return state;
  }
};
