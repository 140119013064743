import axios from "axios";
import { JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

export const createBot = async (orgId, botInfo) => {
  console.log(`create bot for org: ${orgId}`);

  return axiosInstance.post(`${ROOT_URL}api/bots?org-id=${orgId}`, botInfo, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const editBot = async (botId, botInfo) => {
  console.log(`edit bot for bot id: ${botId}`);

  return axiosInstance.put(`${ROOT_URL}api/bots/${botId}`, botInfo, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const sampleSms = async (orgId, smsContent, phoneNumber) => {
  console.log(
    `sample sms api: orgId: ${orgId}, smsContent: ${smsContent}, phoneNumber: ${phoneNumber} `
  );

  return axiosInstance.post(
    `${ROOT_URL}api/bots/sample-sms?org-id=${orgId}`,
    {
      smsContent: smsContent,
      phoneNumber: phoneNumber,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getBots = async (orgId) => {
  console.log(`get bots for org: ${orgId}`);

  return axiosInstance.get(`${ROOT_URL}api/bots?org-id=${orgId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getBot = async (botId) => {
  console.log(`get bot with id: ${botId}`);

  return axiosInstance.get(`${ROOT_URL}api/bots/${botId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getBotMetrics = async (botId, startDate, endDate) => {
  console.log(`get bot metrics with id: ${botId}`);

  return axiosInstance.get(
    `${ROOT_URL}api/bots/${botId}/metrics?startDate=${startDate}&endDate=${endDate}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getBotMetricsCSV = async (botId, startDate, endDate) => {
  console.log(`get bot metrics CSV with id: ${botId}`);

  return axiosInstance.get(
    `${ROOT_URL}api/bots/${botId}/metrics/csv?startDate=${startDate}&endDate=${endDate}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
      // responseType: "blob", // This is to indicate that we expect a binary response
    }
  );
};

export const deleteBot = async (botId) => {
  console.log(`delete bot with id: ${botId}`);

  return axiosInstance.delete(`${ROOT_URL}api/bots/${botId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};
