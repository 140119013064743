import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../models/account/accountActions";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";
import logo from "../../shared/images/vcai-logo2.png";
import { Notifications } from "../Notifications/Notifications";
import { ROLE_ADMIN } from "../../config/contants";
import InsightsIcon from "@mui/icons-material/Insights";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BillingIcon from "@mui/icons-material/Receipt";
import LogoutIcon from "@mui/icons-material/Logout";
import PlaceIcon from "@mui/icons-material/Place";

import { createCustomerPortalSessionAction } from "../../models/stripe/stripeActions";

let pages = [];

function ResponsiveNav() {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const account = useSelector((state) => state.account).account;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const pages = [
    {
      name: "My Locations",
      path: "/",
      icon: <PlaceIcon />,
    },
    {
      name: "Analytics",
      path: "/analytics-dashboard",
      icon: <InsightsIcon />,
    },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar style={{ backgroundColor: "hwb(199deg 7% 83%)" }} position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Button
            style={{
              color: "white",
              fontSize: "22px",
              textTransform: "none",
              fontWeight: "bold",
              border: "none !important",
              outline: "none !important",
              boxShadow: "none !important",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <div
              style={{
                height: "50px",
                width: "50px",
                marginRight: "10px",
                border: "none !important",
                outline: "none !important",
                boxShadow: "none !important",

                position: "relative",
                backgroundPosition: "center center",

                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${logo})`,
              }}
            />
            {isMobile ? "" : "Virtual Contact AI"}
          </Button>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}></Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", marginLeft: "2em" },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => {
                  handleCloseNavMenu();
                  navigate(page.path);
                }}
                sx={{
                  color: "white",
                  display: "block",
                  fontWeight: "bold",
                  textTransform: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    columnGap: "5px",
                    margin: "0px",
                  }}
                >
                  {page.icon}
                  <p>{page.name}</p>
                </div>
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "2em",
              }}
            >
              <Notifications />
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <ProfileAvatar
                    size={50}
                    url={account?.imageUrl}
                    name={account.firstName}
                  />
                </IconButton>
              </Tooltip>
            </div>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                key={"profile"}
                onClick={() => {
                  navigate("/profile");
                  handleCloseUserMenu();
                }}
              >
                <AccountCircleIcon style={{ marginRight: "10px" }} />
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem
                key={"billing"}
                onClick={() => {
                  dispatch(createCustomerPortalSessionAction());
                  handleCloseUserMenu();
                }}
              >
                <BillingIcon style={{ marginRight: "10px" }} />
                <Typography textAlign="center">Manage Billing</Typography>
              </MenuItem>
              <MenuItem
                key={"logout"}
                onClick={() => {
                  dispatch(logoutAction());
                }}
              >
                <LogoutIcon style={{ marginRight: "10px" }} />
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
              {isMobile &&
                pages.map((page) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleCloseNavMenu();
                        navigate(page.path);
                      }}
                    >
                      <span style={{ marginRight: "10px" }}> {page.icon}</span>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  );
                })}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveNav;
