import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import storageSession from "redux-persist/lib/storage/session";

import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import SignupScreen from "./screens/SignUpScreen/SignupScreen";
import { ToastContainer } from "react-toastify";

import { Provider, useDispatch, useSelector } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AdminDashboard from "./screens/AdminDashboard/AdminDashboard";
import MyProfileScreen from "./screens/MyProfileScreen/MyProfileScreen";
import UserDetailsScreen from "./screens/UserDetailsScreen/UserDetailsScreen";
import PinPadVerificationScreen from "./screens/PinPadVerificationScreen/PinPadVerificationScreen";
import OrganizationDashboard from "./screens/OrganizationDashboard/OrganizationDashboard";
import { CompatClient, Stomp } from "@stomp/stompjs";
import { useEffect } from "react";
import { failure } from "./models/uiReducer";
import { incrementUnopenedNotifications } from "./models/notification/notificationReducer";
import SockJS from "sockjs-client";
import {
  ROLE_ADMIN,
  ROLE_INVITED_USER,
  ROLE_USER,
  ROOT_URL,
} from "./config/contants";
import _ from "lodash";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import EmailVerifyScreen from "./screens/EmailVerifyScreen/EmailVerifyScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordFlow/ForgotPasswordScreen/ForgotPasswordScreen";
import ForgotPasswordVerificationScreen from "./screens/ForgotPasswordFlow/ForgotPasswordVerificationScreen/ForgotPasswordVerificationScreen";
import ResetPasswordScreen from "./screens/ForgotPasswordFlow/ResetPasswordScreen/ResetPasswordScreen";
import SetupMFA from "./screens/SetupMFA/SetupMFA";
import AccountSetupSuccessScreen from "./screens/AccountSetupScreen/AccountSetupSuccessScreen";
import AccountSetupOrganization from "./screens/AccountSetupScreen/AccountSetupOrganization";
import AccountSetupPricing from "./screens/AccountSetupScreen/AccountSetupPricing";
import AccountSetupInvitedUser from "./screens/AccountSetupScreen/AccountSetupInvitedUser";
import AnalyticsDashboard from "./screens/AnalyticsDashboard/AnalyticsDashboard";
import RegisterLocation from "./screens/RegisterLocation/RegisterLocation";
import { getOrganizationsAction } from "./models/organization/organizationActions";
import { getAccountAction } from "./models/account/accountActions";
import { registerForWebsocketNotificationsSuccess } from "./models/account/accountReducer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  const account = useSelector((state) => state.account);
  const dispatch = useDispatch();

  let theme = createTheme({
    typography: {
      fontFamily: '"Poppins", sans-serif',
    },
    components: {
      MUIDataTable: {
        styleOverrides: {
          root: {
            fontFamily: '"Poppins", sans-serif',
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          fontFamily: '"Poppins", sans-serif',
        },
      },
    },
    // MuiButton: {
    //   styleOverrides: {
    //     contained: {
    //       fontFamily: '"Poppins", sans-serif',
    //     },
    //   },
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          // Use 'root' to apply styles to the base button component
          fontFamily: '"Poppins", sans-serif',
        },
        contained: {
          fontFamily: '"Poppins", sans-serif',
        },
      },
    },
  });

  theme = responsiveFontSizes(theme);

  let stompClient;
  let sessionId;

  useEffect(() => {
    if (account && account.account && account.account.login) {
      // socket = new SockJS(`${ROOT_URL}ws`);
      // stompClient = Stomp.over(socket);
      stompClient = Stomp.over(function () {
        return new SockJS(`${ROOT_URL}ws`);
      });
      sessionId = "";

      stompClient.heartbeatOutgoing = 20000;
      stompClient.heartbeatIncoming = 0;
      // this is crucial in order to reconnect when spring boot goes down / comes back up
      // the docs incorrectly claim that this defaults to 5000, but it really defaults to 0 which disabled reconnect behavior
      stompClient.reconnectDelay = 10000;

      stompClient.debug = function (str) {
        console.log("StompJS Debug Log:");
        console.log(str);
      };

      stompClient.connect(
        {
          login: account.account.login,
          passcode: account?.accessToken?.split("Bearer ")[1],
        },
        // success callback
        function () {
          console.log("CONNECTION OPENED!");

          sessionId = stompClient.ws._transport.url.split("/");
          sessionId = sessionId[sessionId.length - 1];

          console.log("connected, session id: " + sessionId);

          stompClient.subscribe("/user/queue/private", function (message) {
            const messageBody = message.body;

            console.log("got message with body " + message.body);

            if (
              !_.isNil(messageBody.name) &&
              !_.isNil(messageBody.phoneNumber)
            ) {
              dispatch(getOrganizationsAction());
              dispatch(getAccountAction());
            } else {
              dispatch(incrementUnopenedNotifications());
            }
          });
          stompClient.onWebSocketError(function (evt) {
            console.log("there was a websocket error: ", evt);
          });
          stompClient.onWebSocketClose(function (evt) {
            console.log("the websocket was closed");
          });

          dispatch(registerForWebsocketNotificationsSuccess(stompClient));
        },
        function () {
          console.log("CONNECTION ERROR");
          dispatch(failure());
        }
      );
    }
  }, [account.isSignedIn]);

  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider
        clientId={
          "192051830417-1u7dm89ogv3hkqj7u4mn193n0u8e7hko.apps.googleusercontent.com"
        }
      >
        <BrowserRouter>
          <ScrollToTop />

          <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/sign-up" element={<SignupScreen />} />

            <Route path="/verify-pin" element={<PinPadVerificationScreen />} />
            <Route path="/setup-mfa" element={<SetupMFA />} />

            <Route path="/verify-email" element={<EmailVerifyScreen />} />
            <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
            <Route
              path="/verify-reset-code"
              element={<ForgotPasswordVerificationScreen />}
            />
            <Route path="/reset-password" element={<ResetPasswordScreen />} />

            <Route
              path="/account-setup/organization-setup"
              element={
                <ProtectedRoute mustBeSubscribed={false} nav={false}>
                  <AccountSetupOrganization />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account-setup/pricing"
              element={
                <ProtectedRoute mustBeSubscribed={false} nav={false}>
                  <AccountSetupPricing />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account-setup/user-account"
              element={
                <ProtectedRoute mustBeSubscribed={false} nav={false}>
                  <AccountSetupInvitedUser />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account-setup/success"
              element={
                <ProtectedRoute mustBeSubscribed={false} nav={false}>
                  <AccountSetupSuccessScreen />
                </ProtectedRoute>
              }
            />

            <Route
              path="/account-setup/organization-setup/checkout-success"
              element={
                <ProtectedRoute mustBeSubscribed={false} nav={false}>
                  <AccountSetupSuccessScreen />
                </ProtectedRoute>
              }
            />

            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin-dashboard"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/analytics-dashboard"
              element={
                <ProtectedRoute>
                  <AnalyticsDashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/register-location"
              element={
                <ProtectedRoute>
                  <RegisterLocation />
                </ProtectedRoute>
              }
            />

            <Route
              path="/organization-management/:organization"
              element={
                <ProtectedRoute
                  roles={[ROLE_ADMIN, ROLE_USER, ROLE_INVITED_USER]}
                >
                  <OrganizationDashboard />
                </ProtectedRoute>
              }
            />

            <Route
              path="/user-details/:user"
              element={
                <ProtectedRoute>
                  <UserDetailsScreen />
                </ProtectedRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <MyProfileScreen />
                </ProtectedRoute>
              }
            />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
