import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { stripePlans } from "../../shared/stripePrices";
import { CLIENT_URL } from "../../config/contants";
import { PricingCard } from "../../components/PricingCard/PricingCard";
import "./styles.css";

export const PricingScreen = (props) => {
  const account = useSelector((state) => state.account)?.account;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { orgInfo } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: "2em",
        justifyContent: "space-around",
        // flexWrap: "wrap",
      }}
    >
      {stripePlans.map((plan) => {
        const { title, price, features, id } = plan;
        return (
          <PricingCard
            organizationInfo={orgInfo}
            cancelUrl={`${CLIENT_URL}account-setup/pricing`}
            successUrl={`${CLIENT_URL}account-setup/organization-setup/checkout-success?session_id={CHECKOUT_SESSION_ID}`}
            title={title}
            price={price}
            features={features}
            id={id}
            freeTrial={account?.freeTrialUsed === false}
          />
        );
      })}
    </div>
  );
};

export default PricingScreen;
