import React, { Component, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation, Navigate } from "react-router-dom";
import ResponsiveNav from "../ResponsiveNav/ResponsiveNav";
import { useTheme } from "@mui/material";
import {
  ROLE_ADMIN,
  ROLE_INVITED_USER,
  ROLE_USER,
  STRIPE_SUBSCRIPTIONS,
} from "../../config/contants";
import _ from "lodash";

const ProtectedRoute = ({
  children,
  nav = true,
  roles = [ROLE_ADMIN, ROLE_USER],
  mustBeSubscribed = true,
}) => {
  const account = useSelector((state) => state.account);
  const organization = useSelector(
    (state) => state.organizations
  )?.organization;

  const orgUser = organization?.organizationUsers?.filter(
    (orgUser) => orgUser?.user?.email === account?.account?.email
  )[0];

  const authorities = [orgUser?.role];

  const isSubscribed = () => {
    // check the subscription type of the current org. If no org is set, check the users default org.
    if (!_.isNil(organization) && !_.isNil(organization.id)) {
      console.log("subscription type: ", organization.subscriptionType);
      return STRIPE_SUBSCRIPTIONS.includes(organization.subscriptionType);
    } else {
      return STRIPE_SUBSCRIPTIONS.includes(
        account?.account?.defaultOrganization?.subscriptionType
      );
    }
  };

  const canAccess = () => {
    if (mustBeSubscribed && !isSubscribed()) {
      return false;
    }

    if (organization && organization.id && organization.organizationUsers) {
      // if this is the owner, go right ahead
      if (organization?.owner?.login === account?.account?.email) {
        return true;
      }

      if (!orgUser) {
        return false;
      }

      // at least one of the roles must exist in the authorities
      for (let i = 0; i < roles.length; i++) {
        if (authorities.includes(roles[i])) {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  const {
    mixins: { toolbar },
  } = useTheme();

  let location = useLocation();

  if (!account.isSignedIn) {
    console.log("wasnt logged in: ", account);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!canAccess()) {
    console.log("cant access the route: ", account);
    console.log("cant caccess the route, organization: ", organization);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return nav ? (
    <div style={{ width: "100vw", height: "100vh" }}>
      <ResponsiveNav />
      <div
        style={{
          position: "absolute",
          top: `calc(${toolbar?.minHeight}px + ${8}px)`,
          height: `calc(100vh - (${toolbar?.minHeight}px + ${8}px))`,
          width: "100%",
        }}
      >
        {children}
      </div>
    </div>
  ) : (
    <div style={{ width: "100vw", height: "100vh" }}>{children}</div>
  );
};

export default ProtectedRoute;
