import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, CircularProgress, Container, Link } from "@mui/material";

import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useSelector } from "react-redux";
import { PhoneNumber, PinPad } from "../../components/Form/Form";

import {
  mfaVerifySMSVerificationCode,
  sendSMSVerificationCode,
} from "../../models/account/accountActions";
import { Box } from "@mui/system";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import useRedirect from "../../shared/useRedirect";
import backgroundImage from "../../shared/images/login-bg.png";

const SetupMFA = (props) => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [pins, setPins] = useState([]);
  const ui = useSelector((state) => state.ui);

  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errors }, // here
  } = useForm();

  const handleAddPhoneNumber = () => {
    dispatch(sendSMSVerificationCode(phoneNumber));
    setCodeSent(true);
  };

  const handleMfaVerifySMS = () => {
    dispatch(
      mfaVerifySMSVerificationCode({
        code: pins.join(""),
        phoneNumber: phoneNumber,
      })
    );
  };

  useRedirect();

  const modalStyle = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "400px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      {ui.loading && (
        <div
          style={{
            backgroundColor: "black",
            opacity: "0.5",
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Container
        maxWidth={false}
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100vw",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
      >
        <Box sx={modalStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "1em",
                rowGap: "0",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: "black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "1000px",
                }}
              >
                <FingerprintIcon
                  style={{
                    height: "40px",
                    width: "40px",
                    color: "white",
                  }}
                />
              </div>
              <h2 style={{ margin: "0" }}>Setup Two Factor Authentication</h2>
            </div>

            {codeSent ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  rowGap: "1em",
                  height: "300px",
                }}
              >
                <p style={{ margin: "0px" }}>
                  We sent a 6 digit verification code to your device
                </p>
                <PinPad
                  pinLength={6}
                  pins={pins}
                  setPins={setPins}
                  control={control}
                  errors={errors}
                />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    columnGap: "1em",
                  }}
                >
                  <Button
                    onClick={() => {
                      setCodeSent(false);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    disabled={!isDirty || !isValid}
                    variant="outlined"
                    onClick={handleSubmit(handleMfaVerifySMS)}
                  >
                    Verify
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  rowGap: "1em",
                  width: "100%",
                  height: "300px",
                }}
              >
                <PhoneNumber
                  control={control}
                  phone={phoneNumber}
                  setPhone={(phone) => {
                    setPhoneNumber(phone);
                  }}
                />
                <Button
                  disabled={!isDirty || !isValid} // here
                  variant="outlined"
                  onClick={handleSubmit(handleAddPhoneNumber)}
                >
                  Send Code
                </Button>
              </div>
            )}
          </div>
        </Box>
      </Container>
    </>
  );
};

export default SetupMFA;
