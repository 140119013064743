import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Paper,
  InputAdornment,
  IconButton,
  Link,
  CircularProgress,
} from "@mui/material";
import { forgotPasswordInitAction } from "../../../models/account/accountActions";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
import logo from "../../../shared/images/vcai-logo2.png";
import backgroundImage from "../../../shared/images/login-bg.png";
import useRedirect from "../../../shared/useRedirect";

import {
  Visibility,
  VisibilityOff,
  LockOutlined as LockIcon,
  EmailOutlined as EmailIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

const ForgotPasswordScreen = () => {
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const ui = useSelector((state) => state.ui);

  const onVerifyRecaptcha = useCallback((token) => {
    setRecaptchaToken(token);
  });

  const handleResetPassword = () => {
    dispatch(forgotPasswordInitAction(email))
      .then(unwrapResult)
      .then(() => {
        navigate("/verify-reset-code");
      })
      .catch(() => {
        console.log("this is a failure log for forgot password");
      });
  };

  useRedirect();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {ui.loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 2000,
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Container
        maxWidth={false}
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          p: 0,
          m: 0,
        }}
      >
        <Paper
          elevation={6}
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 0,
            maxWidth: { md: 400 },
            width: isMobile ? "80%" : "400px",
            backgroundColor: "rgba(255, 255, 255, 0.85)",
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Company Logo"
            sx={{ height: "100px", mb: 2 }}
          />
          <Typography variant="h5" sx={{ mb: 3 }}>
            Password Reset
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(handleResetPassword)}
            noValidate
            sx={{ width: "100%" }}
          >
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
            <ReCAPTCHA
              sitekey="6LcffO4lAAAAAC3A0Hdn7Je9-u6Qi6XDotmoG2Ze"
              onChange={onVerifyRecaptcha}
              sx={{ my: 2 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!recaptchaToken}
              sx={{
                mt: 2,
                mb: 1,
                bgcolor: "primary.main",
                "&:disabled": { bgcolor: "grey.500" },
              }}
            >
              Reset Password
            </Button>
          </Box>
          <Link
            href="/login"
            variant="body2"
            sx={{ fontWeight: "bold", mt: 1 }}
          >
            Back to Sign In
          </Link>
          <Link
            href="/sign-up"
            variant="body2"
            sx={{ fontWeight: "bold", mt: 1 }}
          >
            New to the Platform? Sign Up
          </Link>
          <Box sx={{ textAlign: "center", mt: 5 }}>
            <Typography variant="body2" color="textSecondary">
              © {moment().year()} Virtual Contact AI
            </Typography>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default ForgotPasswordScreen;
