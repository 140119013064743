import { createSlice } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";

interface StripeCheckoutSession {
  url: string;
  successUrl: string;
  cancelUrl: string;
  id: string;
  amountTotal: string;
  amountSubtotal: string;
}

interface StripeState {
  checkoutSession: StripeCheckoutSession | {};
  loading: boolean;
}

const initialState: StripeState = {
  checkoutSession: {},
  loading: false,
};

const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    createCheckoutSessionSuccess(state, action) {
      state.checkoutSession = action.payload;
    },

    loading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { createCheckoutSessionSuccess, loading } = stripeSlice.actions;

export default stripeSlice.reducer;
