import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";

const Marker = () => (
  <div
    style={{
      color: "white",
      background: "red",
      padding: "10px",
      display: "inline-flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
      <circle cx="12" cy="10" r="3" />
    </svg>
  </div>
);

const GoogleMapComponent = (props) => {
  const { address } = props;
  const apiKey = "AIzaSyAEojKIicDgs8ffwPe_sOv81vRfIrpr_4o";
  const [center, setCenter] = useState({});
  const [zoom] = useState(18);

  useEffect(() => {
    console.log("address: ", address);

    const geocodeAddress = async (address) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${apiKey}`
        );
        const data = await response.json();

        console.log("geocode data: ", data);
        if (data.status === "OK") {
          console.log("set center to: ", data.results[0].geometry.location);
          setCenter(data.results[0].geometry.location);
        } else {
          console.error("Geocoding failed:", data.status);
        }
      } catch (error) {
        console.error("Error during geocoding:", error);
      }
    };

    if (address) {
      geocodeAddress(address);
    }
  }, [address, apiKey]);

  return (
    <div style={{ height: "330px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: apiKey }}
        center={center}
        defaultZoom={zoom}
      >
        <Marker lat={center.lat} lng={center.lng} />
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMapComponent;
