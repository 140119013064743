import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";

import _ from "lodash";

import { makeStyles } from "@mui/styles";
import PricingScreen from "./PricingScreen";
import { Button } from "@mui/material";
import backgroundImage from "../../shared/images/abstract-background-2.png";

import "./styles.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80%",
      margin: "0px",
    },
  },
  [theme.breakpoints.up("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "800px",
      height: "500px",
      marginTop: "2em",
    },
  },
}));

const AccountSetupPricing = () => {
  console.log("account setup screen");
  const navigate = useNavigate();

  const account = useSelector((state) => state.account);

  useEffect(() => {
    console.log("account setup changed: ", account?.account?.accountSetup);
    if (account && account.account && account.account.accountSetup) {
      console.log("the account is setup");

      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  }, [account?.account?.accountSetup]);

  useEffect(() => {
    if (_.isNil(account?.pendingOrganizationInfo)) {
      navigate("/account-setup/organization-setup");
    }
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh", // Ensure it's at least as tall as the viewport
        display: "flex",
        alignItems: isMobile ? "flex-start" : "center", // Align to top on mobile
        justifyContent: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        margin: 0,
        padding: isMobile ? "20px 0" : 0, // Top and bottom padding on mobile
        overflowY: "auto", // Enable vertical scrolling
        overflowX: "hidden", // Disable horizontal scrolling
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: isMobile ? "80%" : "900px",
          minHeight: "900px", // Minimum height for the content
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              backgroundColor: "white",
            }}
          >
            <p style={{ fontWeight: "bold" }}>2/2</p>
          </div>

          <h1 style={{ fontSize: isMobile ? "24px" : "default" }}>
            Choose the plan that's best for your business
          </h1>
          <p style={{ fontSize: isMobile ? "14px" : "default" }}>
            You can always upgrade or downgrade at a later time.
          </p>

          <div
            style={{
              height: isMobile ? "auto" : "100%",
              width: isMobile ? "100%" : "900px",
            }}
          >
            <PricingScreen orgInfo={account?.pendingOrganizationInfo} />
          </div>
        </div>
        <Button
          style={{ width: "200px", marginTop: isMobile ? "1em" : "0em" }}
          variant="outlined"
          onClick={() => {
            navigate("/account-setup/organization-setup");
          }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default AccountSetupPricing;
