import React, { Component } from "react";

import {
  AddressWithZip,
  Email,
  FirstName,
  LastName,
  PhoneNumber,
  Zip,
} from "../Form/Form";
import _ from "lodash";

const AccountDetails = (props) => {
  const { accountInfo, setAccountInfo, control, setValue } = props;
  const { bankAccount } = accountInfo;

  console.log("the account info!", accountInfo);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}>
        <FirstName
          control={control}
          firstName={accountInfo?.firstName}
          setFirstName={(firstName) => {
            setAccountInfo({ ...accountInfo, firstName: firstName });
          }}
        />

        <LastName
          control={control}
          lastName={accountInfo?.lastName}
          setLastName={(lastName) => {
            setAccountInfo({ ...accountInfo, lastName: lastName });
          }}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}>
        <Email
          control={control}
          email={accountInfo?.email}
          setEmail={(email) => {
            setAccountInfo({ ...accountInfo, email: email });
          }}
        />

        <PhoneNumber
          control={control}
          phone={accountInfo?.phoneNumber}
          setPhone={(phone) => {
            setAccountInfo({ ...accountInfo, phone: phone });
          }}
        />
      </div>
    </div>
  );
};

export default AccountDetails;
