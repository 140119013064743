import React, { useEffect, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

import EmojiTextField from "../EmojiTextField/EmojiTextField";
import "./TextMessageComponent.css";
import { Button, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { PREMIUM } from "../../config/contants";
import LockIcon from "@mui/icons-material/Lock";

const TextMessageComponent = (props) => {
  const organizationSlice = useSelector((state) => state.organizations);
  const organization = organizationSlice.organization;
  const subscriptionType = organization?.subscriptionType;
  const isPremium = subscriptionType === PREMIUM;

  const { smsContent, setSmsContent } = props;

  const [isEditing, setIsEditing] = useState({
    greeting: false,
    hours: false,
    email: false,
    links: Array(5).fill(false),
  });

  const enterEditMode = (section, index = null) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [section]:
        index != null ? prevState[section].map((_, i) => i === index) : true,
    }));
  };

  const exitEditMode = (section, index = null) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [section]: index != null ? prevState[section].map(() => false) : false,
    }));
  };

  const updateContentSection = (section, index, key, value) => {
    let content = { ...smsContent };

    content[section] =
      index != null
        ? content[section].map((item, i) =>
            i === index ? { ...item, [key]: value } : item
          )
        : { ...content[section], [key]: value };

    console.log("the content is: ", content);
    setSmsContent(content);
  };

  const renderEditableField = (section, index = null) => {
    const contentSection =
      index != null ? smsContent[section][index] : smsContent[section];
    return (
      <EmojiTextField
        emoji={contentSection.emoji}
        text={contentSection.label}
        onEmojiSelect={(newEmoji) =>
          updateContentSection(section, index, "emoji", newEmoji)
        }
        onTextChange={(newText) =>
          updateContentSection(section, index, "label", newText)
        }
        onBlur={() => exitEditMode(section, index)}
        exitEditMode={() => exitEditMode(section, index)}
      />
    );
  };

  const removeLink = (id) => {
    console.log("links before remove: ", smsContent.links);
    const updatedLinks = smsContent.links.filter((link) => link.id !== id);
    console.log("links after remove: ", updatedLinks);

    setSmsContent({
      ...smsContent,
      links: updatedLinks,
    });
  };

  const renderLinkItem = (link, index) => (
    <div key={index} className="link-item">
      {isEditing.links[index] ? (
        renderEditableField("links", index)
      ) : (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
          onClick={() => {
            console.log("entering edit mode");
            enterEditMode("links", index);
          }}
        >
          {link.emoji} {link.label}
          <DeleteIcon
            className="remove-icon"
            onClick={(e) => {
              console.log("Removing link");
              e.stopPropagation(); // Prevent triggering the click event on the parent
              removeLink(link.id);
            }}
          />
        </span>
      )}
    </div>
  );

  return (
    <div className="text-message-container">
      <div className="section" onClick={() => enterEditMode("greeting")}>
        {isEditing.greeting ? (
          renderEditableField("greeting")
        ) : (
          <span>
            {smsContent?.greeting?.emoji} {smsContent?.greeting?.label}
          </span>
        )}
      </div>

      {isPremium ? (
        <div className="section links-section">
          {smsContent?.links?.map((link, index) => renderLinkItem(link, index))}
        </div>
      ) : (
        <div className="section links-section">
          {smsContent?.links?.length > 0 &&
            renderLinkItem(smsContent.links[0], 0)}
          <div className="grayed-out-links">
            {smsContent?.links?.slice(1).map((link, index) => (
              <Tooltip title="Your subscription is limited to one link. To add more links, you'll have to upgrade to Premium.">
                <div key={index} className="grayed-out-link-item">
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {link.emoji} {link.label}
                  </span>
                  <LockIcon className="lock-icon" />
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      )}

      <Tooltip
        title="Your store hours come directly from your Google Business Profile, and are not editable here."
        placement="bottom"
      >
        <div style={{ cursor: "default" }} className="section">
          <pre className="custom-pre">
            {smsContent?.hours?.emoji} {smsContent?.hours?.label}
          </pre>
        </div>
      </Tooltip>

      <div className="section" onClick={() => enterEditMode("email")}>
        {isEditing.email ? (
          renderEditableField("email")
        ) : (
          <span>
            {smsContent?.email?.emoji} {smsContent?.email?.label}
          </span>
        )}
      </div>
    </div>
  );
};

export default TextMessageComponent;
