import { Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const KpiComponent = ({ title, subTitle, IconComponent, iconColor }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Paper
      sx={{
        width: isMobile ? "120px" : "220px",
        height: isMobile ? "90px" : "120px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: 2,
      }}
      elevation={1}
    >
      {IconComponent && (
        <IconComponent sx={{ fontSize: "2rem", color: iconColor }} />
      )}
      <Typography
        variant="h3"
        component="h1"
        sx={{ margin: "0px", fontWeight: "bold" }}
      >
        {title}
      </Typography>
      <Typography variant="subtitle1" component="h6" sx={{ margin: "0px" }}>
        {subTitle}
      </Typography>
    </Paper>
  );
};

export default KpiComponent;
