import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrganizationDetails from "../../components/OrganizationDetails/OrganizationDetails";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { stripePlans } from "../../shared/stripePrices";
import { getAccountAction } from "../../models/account/accountActions";
import { Swiper, SwiperSlide } from "swiper/react";
import backgroundImage from "../../shared/images/abstract-background-2.png";
import LinkIcon from "@mui/icons-material/Link";

import { Navigation } from "swiper";
import "./styles.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useNavigate } from "react-router-dom";
import { CLIENT_URL } from "../../config/contants";
import { PricingCard } from "../../components/PricingCard/PricingCard";
import BusinessSearch from "../../components/BusinessSearch/BusinessSearch";

const RegisterLocation = () => {
  const { control, handleSubmit, reset, setValue } = useForm();
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const account = useSelector((state) => state.account)?.account;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [organizationInfo, setOrganizationInfo] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    address: {
      city: "",
      state: "",
      zip: "",
      place: {
        description: "",
        place_id: "",
      },
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccountAction());
  }, []);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleGoBack = () => {
    setStep(0);
  };

  const placesSearchStep = (
    <div style={{ height: "100%" }}>
      <BusinessSearch
        control={control}
        setValue={setValue}
        organizationInfo={organizationInfo}
        setOrganizationInfo={setOrganizationInfo}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "1em",
          marginTop: "2em",
        }}
      >
        <Button
          style={{ width: "200px", marginTop: "3em" }}
          variant="outlined"
          onClick={() => {
            navigate("/");
          }}
        >
          Go Back
        </Button>
        <Button
          style={{ width: "200px", marginTop: "3em" }}
          variant="outlined"
          onClick={handleSubmit(handleNext)}
        >
          Next
        </Button>
      </div>
    </div>
  );

  const subscriptionStep = (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          backgroundColor: "white",
        }}
      >
        <p style={{ fontWeight: "bold" }}>2/2</p>
      </div>
      <h1 style={{ fontSize: isMobile ? "24px" : "default" }}>
        Choose the plan that's best for your business
      </h1>
      <p style={{ fontSize: isMobile ? "14px" : "default" }}>
        You can always upgrade or downgrade at a later time.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          columnGap: "2em",
          marginTop: "2em",
          height: isMobile ? "auto" : "100%",
          width: isMobile ? "100%" : "900px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "2em",
          }}
        >
          {stripePlans.map((plan) => {
            const { title, price, features, id } = plan;
            return (
              <PricingCard
                organizationInfo={organizationInfo}
                cancelUrl={`${CLIENT_URL}register-location`}
                successUrl={`${CLIENT_URL}register-location/checkout-success?session_id={CHECKOUT_SESSION_ID}`}
                title={title}
                price={price}
                features={features}
                id={id}
                freeTrial={account?.freeTrialUsed === false}
              />
            );
          })}
        </div>
      </div>
      <Button
        style={{ width: "200px", marginTop: "3em" }}
        variant="outlined"
        onClick={handleGoBack}
      >
        Go Back
      </Button>
    </div>
  );

  const steps = [placesSearchStep, subscriptionStep];

  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh", // Ensure it's at least as tall as the viewport
        display: "flex",
        alignItems: isMobile ? "flex-start" : "center", // Align to top on mobile
        justifyContent: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        margin: 0,
        padding: isMobile ? "20px 0" : 0, // Top and bottom padding on mobile
        overflowY: "auto", // Enable vertical scrolling
        overflowX: "hidden", // Disable horizontal scrolling
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: isMobile ? "80%" : "900px",
          minHeight: "900px",
        }}
      >
        {steps[step]}
      </div>
    </div>
  );
};

export default RegisterLocation;
