import {
  createBotSuccess,
  deleteBotSuccess,
  getBotMetricsSuccess,
  getBotsSuccess,
  getBotSuccess,
  loading,
  failure,
  sampleSmsSuccess,
  editBotSuccess,
} from "./botReducer";

import _ from "lodash";
import { toastFailure, toastSuccess } from "../uiReducer";
import {
  createBot,
  deleteBot,
  editBot,
  getBot,
  getBotMetrics,
  getBotMetricsCSV,
  getBots,
  getObservedHolidays,
  sampleSms,
} from "./botApi";

export const createBotThunk = (orgId, botInfo) => async (dispatch) => {
  console.log(`handle create bot for org ${orgId}`);
  dispatch(loading(true));

  try {
    const response = await createBot(orgId, botInfo);

    console.log("response: ", JSON.stringify(response));

    dispatch(createBotSuccess(response.data));
    toastSuccess("Success!", "Your bot is now live");
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const editBotThunk = (botId, botInfo) => async (dispatch) => {
  console.log(`handle edit bot for bot id ${botId}`);
  dispatch(loading(true));

  try {
    const response = await editBot(botId, botInfo);

    console.log("response: ", JSON.stringify(response));

    dispatch(editBotSuccess(response.data));
    toastSuccess("Success!", "The changes to your bot are live!");
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const sampleSmsThunk =
  (orgId, botInfo, phoneNumber) => async (dispatch) => {
    console.log(
      `handle sample sms orgId: ${orgId}, botInfo: ${botInfo}, phoneNumber: ${phoneNumber}`
    );

    try {
      const response = await sampleSms(orgId, botInfo, phoneNumber);

      console.log("response: ", JSON.stringify(response));
      toastSuccess("Success!", "You should receive the sample SMS shortly.");

      dispatch(sampleSmsSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const getBotsThunk = (orgId) => async (dispatch) => {
  console.log(`handle get bots for org ${orgId}`);
  dispatch(loading(true));

  try {
    const response = await getBots(orgId);

    console.log("response: ", JSON.stringify(response));

    dispatch(getBotsSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getBotThunk = (botId) => async (dispatch) => {
  console.log(`handle get bot with id ${botId}`);
  dispatch(loading(true));

  try {
    const response = await getBot(botId);

    console.log("response: ", JSON.stringify(response));

    dispatch(getBotSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const deleteBotThunk = (botId) => async (dispatch) => {
  console.log(`handle delete bot with id ${botId}`);
  dispatch(loading(true));

  try {
    const response = await deleteBot(botId);

    console.log("response: ", JSON.stringify(response));

    dispatch(deleteBotSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getBotMetricsThunk =
  (botId, startDate, endDate) => async (dispatch) => {
    console.log(`handle get bot metrics with id ${botId}`);
    dispatch(loading(true));

    try {
      const response = await getBotMetrics(botId, startDate, endDate);

      dispatch(getBotMetricsSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const getBotMetricsCsvThunk =
  (botId, startDate, endDate) => async (dispatch) => {
    console.log(`handle get bot metrics csv with id ${botId}`);
    dispatch(loading(true));

    try {
      const response = await getBotMetricsCSV(botId, startDate, endDate);

      console.log("response: ", response);

      const contentDisposition = response.headers["content-disposition"];

      console.log("the content disposition: ", contentDisposition);

      let filename = "data.csv"; // default filename
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch.length === 2) {
          console.log("setting the filename to: ", filenameMatch);
          filename = filenameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      // toastSuccess("File downloaded successfully");
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };
