import { InputAdornment, Popover, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Picker from "emoji-picker-react";

const EmojiTextField = ({
  emoji,
  text,
  onEmojiSelect,
  onTextChange,
  onBlur,
  onKeyPress,
  exitEditMode,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEmoji, setSelectedEmoji] = useState(emoji); // The initially selected emoji
  const [inputText, setInputText] = useState(text); // The text without the emoji

  const maxLength = 350;

  // Regex to match emojis
  const emojiRegex =
    /(\ud83c[\udf00-\udfff]|\ud83d[\udc00-\ude4f]|\ud83d[\ude80-\udeff])/;

  const handlePaste = (event) => {
    // Get the text from the clipboard
    const pasteText = event.clipboardData.getData("text");

    // Check if the pasted text contains an emoji
    if (emojiRegex.test(pasteText)) {
      // Prevent the paste action
      event.preventDefault();
      // Optionally, you can alert the user or provide a message
      alert("Pasting emojis is not allowed.");
    }
  };

  const handleEmojiClick = (event) => {
    // Prevents the click from immediately closing the popover
    event.stopPropagation();
    // Toggle the popover
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleEmojiSelect = (emojiObject) => {
    const newEmoji = emojiObject.emoji;
    setSelectedEmoji(newEmoji); // Update the local state with the new emoji
    setAnchorEl(null); // Close the emoji picker popover

    // Here we call onEmojiSelect with only the new emoji, since text is not changed yet
    onEmojiSelect(newEmoji);
  };

  const handleTextChange = (event) => {
    const newText = event.target.value;
    // If the new text is within the length limit, update the state
    if (newText.length <= maxLength) {
      setInputText(newText);
      onTextChange(newText);
    } else {
      // Optionally, you could alert the user that they've exceeded the limit
      alert("Text exceeds the maximum length allowed.");
    }
  };

  const handleKeyPressEvent = (event) => {
    if (event.key === "Enter") {
      // Prevent the default enter key action
      event.preventDefault();
      // Trigger the onKeyPress event with the current emoji and text
      onKeyPress(selectedEmoji + inputText);
    }
  };

  // When the TextField loses focus, call the onBlur handler with the current state
  const handleBlurEvent = () => {
    if (onBlur) {
      onBlur(selectedEmoji + inputText);
    }
  };

  const componentRef = useRef(null);

  const popoverRef = useRef(null); // Ref for the Popover

  const handleOutsideClick = (event) => {
    // If the click is inside the component, ignore it.
    if (componentRef.current && componentRef.current.contains(event.target)) {
      return;
    }

    // If the click is inside the popover, ignore it.
    if (popoverRef.current && popoverRef.current.contains(event.target)) {
      return;
    }

    // If we get here, the click is outside both the component and the popover.
    exitEditMode();
  };

  useEffect(() => {
    // Add when the component mounts
    document.addEventListener("mousedown", handleOutsideClick);
    // Remove when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []); // Empty array ensures this only happens on mount/unmount

  return (
    <div ref={componentRef}>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={inputText} // Display only the text part
        onChange={handleTextChange}
        onBlur={handleBlurEvent}
        onPaste={handlePaste} // Add this line
        onKeyPress={handleKeyPressEvent}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <div onClick={handleEmojiClick} style={{ cursor: "pointer" }}>
                {selectedEmoji}
              </div>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        ref={popoverRef} // Attach the ref here
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Picker onEmojiClick={handleEmojiSelect} />
      </Popover>
    </div>
  );
};

export default EmojiTextField;
