import React, { useState } from "react";

import { PhoneNumber } from "../Form/Form";
import _ from "lodash";
import { Button, IconButton, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import HorizontalLinearStepper from "../../screens/AccountSetupScreen/HorizontalLinearStepper";

import {
  createBotThunk,
  editBotThunk,
  sampleSmsThunk,
} from "../../models/bots/botThunks";
import {
  BotName,
  BotVoice,
  SmsGreeting,
  SmsLink,
  VoiceGreeting,
} from "../Form/BotForm";

import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"; // Suggests automation features
import TextToSpeechSample from "../TextToSpeechSample/TextToSpeechSample";
import TextMessageComponent from "../TextMessageComponent/TextMessageComponent";
import SendIcon from "@mui/icons-material/Send";
import ConfigureSmsComponent from "../ConfigureSmsComponent/ConfigureSmsComponent";

const BotSteps = (props) => {
  const organization = useSelector((state) => state.organizations);
  const botSlice = useSelector((state) => state.bot);

  const {
    botInfo,
    setBotInfo,
    control,
    setValue,
    handleSubmit,
    canClose,
    setCanClose,
    mode,
  } = props;
  const [activeStep, setActiveStep] = useState(0);

  console.log("the mode from steps: ", mode);

  const createBotSteps = [
    {
      title: "",
      subTitle: "Do you want a male or female voice?",
      handleSubmit: () => {
        setActiveStep(activeStep + 1);
      },
      panel: (
        <div style={{ width: "100%" }}>
          <p>
            {" "}
            <i>Your bot can have a male or female sounding voice. </i>
          </p>
          <BotVoice
            control={control}
            botVoice={botInfo.botVoice}
            setBotVoice={(voice) => {
              setBotInfo({ ...botInfo, botVoice: voice });
            }}
          />
          <p>
            {" "}
            <i>How should your bot greet customers when it answers a call?</i>
          </p>
          <VoiceGreeting
            voiceId={botInfo.botVoice}
            control={control}
            orgName={organization?.organization?.name}
            voiceGreeting={botInfo.voiceGreeting}
            setVoiceGreeting={(voiceGreeting) => {
              setBotInfo({ ...botInfo, voiceGreeting: voiceGreeting });
            }}
          />
        </div>
      ),
    },
    {
      title: "",
      subTitle:
        "If a customer absolutely has to speak to a human, which number should we forward to?",
      handleSubmit: () => {
        setActiveStep(activeStep + 1);
      },
      panel: (
        <div style={{ width: "100%" }}>
          <p>
            {" "}
            <i>
              If a customer has to speak to a human, or if there is an error,
              which number should we forward to?
            </i>
          </p>
          <PhoneNumber
            name="Transfer Phone Number"
            control={control}
            phone={botInfo?.transferPhoneNumber}
            setPhone={(phone) => {
              setBotInfo({
                ...botInfo,
                transferPhoneNumber: phone,
              });
            }}
          />
        </div>
      ),
    },

    {
      title: "",
      subTitle: "Let's get the SMS links all set up",
      handleSubmit: async () => {
        console.log("inside bot submit, the mode was: ", mode);
        await dispatch(
          mode == "edit" && botSlice.bots.length > 0
            ? editBotThunk(botSlice.bots[0]?.id, botInfo)
            : createBotThunk(organization?.organization?.id, botInfo)
        );

        if (_.isNil(botSlice.error)) {
          setCanClose(true);
        }
      },
      panel: (
        <ConfigureSmsComponent
          control={control}
          handleSubmit={handleSubmit}
          botInfo={botInfo}
          setBotInfo={setBotInfo}
        />
      ),
    },
  ];

  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "1em",
      }}
    >
      <HorizontalLinearStepper
        canFinish={true}
        activeStep={activeStep}
        handleGoBack={() => {
          setActiveStep(activeStep - 1);
        }}
        stepperTitle={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              rowGap: "8px",
              alignItems: "center",
              marginBottom: "1em",
            }}
          >
            <AutoFixHighIcon
              sx={{ color: "#D4AF37", marginBottom: "5px", merginRight: "5px" }}
            />
            <h2 style={{ marginTop: "0px", marginBottom: "0em" }}>Bot Setup</h2>
          </div>
        }
        stepperSubtitle={
          <p style={{ marginTop: "10px", marginBottom: "2em" }}>
            Your bot will be live in just a few quick steps!
          </p>
        }
        stepperCompletedMessage={"Hang tight while we create your bot"}
        steps={createBotSteps}
        control={control}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default BotSteps;
