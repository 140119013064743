import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";

import {
  Button,
  IconButton,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import OrganizationsTable from "../../components/OrganizationsTable/OrganizationsTable";
import { getOwnedOrganizationsAction } from "../../models/organization/organizationActions";
import BusinessCard from "../../components/BusinessCard/BusinessCard";
import StorefrontIcon from "@mui/icons-material/Storefront";
import backgroundImage from "../../shared/images/abstract-background-2.png";
import KpiComponent from "../../components/KpiComponent/KpiComponent";
import { getOrganizationsAction } from "../../models/organization/organizationActions";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const organizationSlice = useSelector((state) => state.organizations);
  const organizations = useSelector(
    (state) => state.organizations
  ).organizations;

  const currentUserEmail = account.account.email;

  const [cardView, setCardView] = useState(true);

  const [addOrganization, setAddOrganization] = useState(false);
  //   const merchant = useSelector((state) => state.merchant);
  const { control, handleSubmit, errors } = useForm();

  console.log("the account: ", account);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizationsAction());
  }, []);

  const handleChangeView = () => {
    setCardView(!cardView);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let kpiData = [
    {
      title: `${organizations ? organizations.length : "--"}`,
      subTitle: "Locations",
      IconComponent: StorefrontIcon,
      iconColor: "#4caf50",
    },
  ];

  return (
    <div
      style={{
        height: isMobile ? "auto" : "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        margin: 0,
        padding: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: isMobile ? "80%" : "75%",
          padding: "2em",
          flexDirection: "column",
        }}
      >
        <div style={{ marginTop: "3em", marginBottom: "3em" }}>
          <h1
            style={{ margin: "0px", fontSize: isMobile ? "24px" : "default" }}
          >
            Business Registration
          </h1>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "70%",
            rowGap: "2em",
            columnGap: "2em",
            flexWrap: "wrap",
            marginBottom: "2em",
          }}
        >
          {kpiData.map((card, index) => (
            <KpiComponent
              key={index}
              title={card.title}
              subTitle={card.subTitle}
              IconComponent={card.IconComponent}
              iconColor={card.iconColor}
            />
          ))}
        </div>
        <div style={{ width: "100%", paddingBottom: "2em" }}>
          {isMobile ? null : (
            <Stack
              style={{ marginTop: "2em", marginBottom: "2em" }}
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <Typography>Table</Typography>
              <Switch
                checked={cardView}
                onChange={handleChangeView}
                inputProps={{ "aria-label": "controlled" }}
              />

              <Typography>Cards</Typography>
            </Stack>
          )}

          {cardView ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "2em",
                }}
              >
                <h2 style={{ margin: "0" }}>Locations</h2>
                <Button
                  variant="contained"
                  endIcon={<AddIcon />}
                  onClick={() => {
                    navigate("register-location");
                  }}
                  style={{
                    borderRadius: "20px", // Rounded corners
                    background: "linear-gradient(to right, #4caf50, #087f23)", // Greener linear gradient
                    color: "#fff", // White text for contrast
                    textTransform: "none", // Sentence case
                    fontSize: "1rem", // Adjust font size as needed
                    boxShadow: "none", // No shadow
                  }}
                >
                  Add location
                </Button>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.5em",
                  flexWrap: "wrap",
                }}
              >
                {organizations.map((place) => {
                  let me = place?.organizationUsers.find(
                    (orgUser) => orgUser?.user?.login === currentUserEmail
                  );

                  let myRole =
                    place?.owner?.login === currentUserEmail
                      ? "OWNER"
                      : me.role;

                  return <BusinessCard role={myRole} business={place} />;
                })}
              </div>
            </>
          ) : (
            <OrganizationsTable
              setAddOrganization={setAddOrganization}
              data={organizations}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
