export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const JWT = "JWT";
export const GOOGLE_TOKEN = "GOOGLE_TOKEN";
export const applicationJson = "application/json";

export const getToken = async (key: string) => {
  try {
    const value = sessionStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    throw e;
  }
};

export const storeToken = async (key: string, value: string) => {
  console.log("STORE TOKEN INVOKATION");
  try {
    sessionStorage.setItem(key, value);
  } catch (e) {
    throw e;
  }
};

export const revokeToken = async (key: string) => {
  console.log("revoke token");
  try {
    sessionStorage.removeItem(key);
  } catch (e) {
    throw e;
  }
};

const nodeEnv = process.env.NODE_ENV;

export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_USER = "ROLE_USER";
export const ROLE_INVITED_USER = "ROLE_INVITED_USER";
export const BASIC = "BASIC";
export const PRO = "PRO";
export const PREMIUM = "PREMIUM";

export const blurredForbiddenStyle = {
  filter: "blur(8px)",
  userSelect: "none",
  pointerEvents: "none",
};

export const PRO_GRADIENT =
  "linear-gradient(90deg, rgba(194, 209, 255, 1) 0%, rgba(179, 114, 254, 1) 100%)";

export const PREMIUM_GRADIENT =
  "linear-gradient(90deg, rgba(255, 177, 195, 1) 0%, rgba(255, 203, 112, 1) 100%)";

export const BASIC_GRADIENT = "";

export const STRIPE_SUBSCRIPTIONS = [BASIC, PRO, PREMIUM];

export const CLIENT_URL =
  nodeEnv === "production"
    ? "https://app.virtualcontactai.com/"
    : "http://localhost:3000/";

export const ROOT_URL =
  nodeEnv === "production"
    ? "https://api.virtualcontactai.com/"
    : "http://localhost:8080/";

export const PREMIUM_PRICE =
  process.env.REACT_APP_PREMIUM_PRICE_PROD ||
  process.env.REACT_APP_PREMIUM_PRICE_DEV;

export const PRO_PRICE =
  process.env.REACT_APP_PRO_PRICE_PROD || process.env.REACT_APP_PRO_PRICE_DEV;

console.log(`the node env is: ${nodeEnv}`);
