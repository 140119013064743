import React, { useState } from "react";

import OrganizationDetails from "../../components/OrganizationDetails/OrganizationDetails";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import LinkIcon from "@mui/icons-material/Link";
import Alert from "@mui/material/Alert";

import "../../screens/AccountSetupScreen/styles.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const BusinessSearch = (props) => {
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  const { control, setValue, organizationInfo, setOrganizationInfo } = props;
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          backgroundColor: "white",
        }}
      >
        <p style={{ fontWeight: "bold" }}>1/2</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1em",
        }}
      >
        <LinkIcon sx={{ fontSize: 40 }} />
        <h1 style={{ fontSize: isMobile ? "24px" : "default" }}>
          Let's link your Google Business
        </h1>
      </div>

      <p style={{ fontSize: isMobile ? "14px" : "default" }}>
        We use your Google Business Profile to capture information about your
        business, such as your hours of opperation. This way, you can manage
        your business data in one place, and your bot will dynamically reflect
        those changes.
      </p>
      <Alert severity="info">
        This dashboard currently supports Restaurants only, but we also build
        custom AI phone integrations for{" "}
        <span
          style={{
            backgroundImage:
              "linear-gradient(to right, #56CCF2 0%, #2F80ED 100%)", // Blue/Green gradient
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 6px",
            backgroundPosition: "0 calc(100% + 1px)", // Adjusted for better visual alignment
            cursor: "default", // Changed from 'pointer' to 'default'
            textDecoration: "none",
            paddingBottom: "2px", // Ensures the line doesn't overlap with text descenders
          }}
        >
          all business types
        </span>
        . If you don't find your business in this search, please{" "}
        <a
          href="https://virtualcontactai.com/contact/"
          target="_blank"
          rel="noopener noreferrer"
        >
          contact us
        </a>{" "}
        to discuss your use-case, and we'll usually have your bot live in 3 days
        or less.
      </Alert>
      <OrganizationDetails
        control={control}
        setValue={setValue}
        organizationInfo={organizationInfo}
        setOrganizationInfo={setOrganizationInfo}
      />
      <div style={{ height: "300px" }}>
        {organizationInfo.name && (
          <div>
            <h3>{`We found a Google Business Profile for ${organizationInfo.name}`}</h3>
            <>
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                slidesPerView={isMobile ? 1 : 2}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
              >
                {organizationInfo?.photos?.map((photo) => {
                  return (
                    <SwiperSlide>
                      <img
                        style={{ height: "250px", maxHeight: "250px" }}
                        src={photo}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessSearch;
