import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { createCheckoutSessionAction } from "../../models/stripe/stripeActions";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./styles.css";

export const PricingCard = (props) => {
  const {
    title,
    price,
    features,
    id,
    freeTrial,
    cancelUrl,
    successUrl,
    organizationInfo,
  } = props;
  const dispatch = useDispatch();
  const buttonText = freeTrial ? "Try free for 14 days" : "Get Started";

  const handleButtonClick = (isFreeTrial) => {
    dispatch(
      createCheckoutSessionAction({
        priceId: id,
        cancelUrl,
        successUrl,
        googlePlace: organizationInfo,
        freeTrial: isFreeTrial,
      })
    );
  };

  const titleBackgrounds = {
    Basic: "#f8fafe",
    Pro: "linear-gradient(90deg, rgba(194, 209, 255, 1) 0%, rgba(179, 114, 254, 1) 100%)",
    Premium:
      "linear-gradient(90deg, rgba(255, 177, 195, 1) 0%, rgba(255, 203, 112, 1) 100%)",
  };

  return (
    <Card
      sx={{
        flex: "1 1 auto",
        maxWidth: { xs: "100%", sm: 400 },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "auto",
      }}
    >
      <CardContent>
        <Box
          sx={{
            height: "2em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: titleBackgrounds[title],
            color: title === "Basic" ? "#000" : "#fff",
            padding: "10px",
            fontSize: "30px",
            fontWeight: "400",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          <h5
            style={{
              margin: "0px",
              letterSpacing: "3px",
              fontWeight: "normal",
            }}
          >
            {title}
          </h5>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            "& .price": {
              fontSize: "40px",
              fontWeight: "bold",
            },
            "& .sub": {
              fontSize: "14px",
              fontWeight: "normal",
            },
          }}
        >
          <Typography className="price">
            ${price}{" "}
            <Box component="sub" className="sub">
              / month
            </Box>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            marginTop: "1em",
          }}
        >
          {features.map((feature, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                "& .feature-text": {
                  fontSize: "14px",
                  margin: "0px",
                  marginLeft: "0.5em",
                },
              }}
            >
              <CheckCircleOutlineIcon sx={{ color: "#6fc385" }} />
              <Typography className="feature-text">{feature}</Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
      <CardActions>
        <Box
          sx={{
            width: "100%",
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => handleButtonClick(freeTrial)}
            size="large"
            fullWidth
            className="fill-color-button"
          >
            {buttonText}
          </Button>
          {freeTrial && (
            <Button
              sx={{
                background: "none",
                border: "none",
                color: "text.primary",
                textDecoration: "underline",
                fontSize: "13px",
                cursor: "pointer",
                marginTop: "0.5em",
                "&:hover": {
                  textDecoration: "none",
                  background: "none",
                },
              }}
              onClick={() => handleButtonClick(false)}
            >
              or purchase now
            </Button>
          )}
        </Box>
      </CardActions>
    </Card>
  );
};
