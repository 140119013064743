import { createCheckoutSessionSuccess } from "./stripeReducer";

import _ from "lodash";
import { failure, loading, toastFailure } from "../uiReducer";
import {
  createCheckoutSession,
  createCustomerPortalSession,
} from "./stripeApi";

export const createCheckoutSessionAction =
  (checkoutSessionRequest) => async (dispatch) => {
    console.log(`handle create checkout session`);
    dispatch(loading(true));

    try {
      const response = await createCheckoutSession(checkoutSessionRequest);

      dispatch(createCheckoutSessionSuccess(response.data));
      window.location.href = response.data.url;
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };

export const createCustomerPortalSessionAction = () => async (dispatch) => {
  console.log(`handle create customer portal session`);
  dispatch(loading(true));

  try {
    const response = await createCustomerPortalSession();

    console.log("response data url: ", response);

    window.location.href = response.data.url;
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};
