import { Card, CardActionArea, Chip } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getOrganizationAction } from "../../models/organization/organizationActions";
import { useNavigate } from "react-router-dom";
import {
  PREMIUM_GRADIENT,
  PRO_GRADIENT,
  ROLE_ADMIN,
  ROLE_USER,
} from "../../config/contants";
import KeyIcon from "@mui/icons-material/Key";
import PersonIcon from "@mui/icons-material/Person";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useEffect, useState } from "react";

const RoleChip = (props) => {
  const { role } = props;
  let chipProperties = {
    label: null,
    icon: null,
    style: {},
  };

  switch (role) {
    case "OWNER":
      chipProperties.icon = <KeyIcon />;
      chipProperties.label = "Role: Owner";
      chipProperties.style = {
        background: "linear-gradient(to right, #f6e8c3, #ede0d4)",
      }; // Gold gradient
      break;
    case "ROLE_USER":
      chipProperties.icon = <PersonIcon />;
      chipProperties.label = "Role: Read Only";
      chipProperties.style = {
        background: "linear-gradient(to right, #a1c4fd, #c2e9fb)",
      }; // Green gradient
      break;
    case "ROLE_ADMIN":
      chipProperties.icon = <AdminPanelSettingsIcon />;
      chipProperties.label = "Role: Admin";
      chipProperties.style = {
        background: "linear-gradient(to right, #2F80ED, #56CCF2)",
      }; // Blue gradient
      break;
    default:
      chipProperties.label = "Role: Unknown";
  }

  return (
    <Chip
      style={chipProperties.style}
      icon={chipProperties.icon}
      label={chipProperties.label}
    />
  );
};

const BusinessCard = (props) => {
  const { business, role } = props;
  console.log("business: ", business);

  console.log("role: ", role);

  console.log("props: ", props);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ownerName = `${business.owner.firstName} ${business.owner.lastName}`;
  const address = `${business.address.city}, ${business.address.state} ${business.address.zip}`;

  const formatCustomDate = (date) => {
    const now = moment(); // Local time now
    const inputDate = moment.utc(date).local(); // Convert input date from UTC to local time

    if (inputDate.isSame(now, "day")) {
      return `today @ ${inputDate.format("h:mma")}`;
    } else if (inputDate.isSame(now.clone().subtract(1, "day"), "day")) {
      return `yesterday @ ${inputDate.format("h:mma")}`;
    } else {
      return inputDate.format("M/DD/YYYY @ h:mma");
    }
  };

  const formatSubscriptionActiveDate = (date) => {
    if (date == null) {
      return '';
    }
    const year = date[0];
    const month = date[1];
    const day = date[2];
    return ' (until ' + date[1] + '/' + date[2] + '/' + date[0] + ')';
  }

  const handleClickBusiness = () => {
    dispatch(getOrganizationAction(business.id));

    navigate(`organization-management/${business.name}}`, {
      organizationName: business.name,
    });
  };

  const getSubscriptionChip = (subscriptionType) => {
    const chipStyles = {
      width:
        subscriptionType === "PREMIUM" || subscriptionType === "NONE"
          ? (business.currentSubscriptionExpiration  ? "290px" : "175px")
          : (business.currentSubscriptionExpiration  ? "275px" : "150px"),
      color: "#fff",
    };

    switch (subscriptionType) {
      case "PRO":
        return (
          <Chip
            style={{ ...chipStyles, background: PRO_GRADIENT }}
            label={`Subscription: Pro` + formatSubscriptionActiveDate(business.currentSubscriptionExpiration)}
          />
        );
      case "PREMIUM":
        return (
          <Chip
            style={{ ...chipStyles, background: PREMIUM_GRADIENT }}
            label={`Subscription: Premium` + formatSubscriptionActiveDate(business.currentSubscriptionExpiration)}
          />
        );
      case "BASIC":
        return (
          <Chip
            style={{
              ...chipStyles,
              background: "linear-gradient(to right, #1e3c72, #2a5298)",
            }} // Blue gradient
            label={`Subscription: Basic` + formatSubscriptionActiveDate(business.currentSubscriptionExpiration)}
          />
        );
      case "NONE":
        return (
          <Chip
            style={{ ...chipStyles, background: "red" }}
            label={`Subscription: Inactive`}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Card
      onClick={() => {
        if (business.subscriptionType !== "NONE") {
          handleClickBusiness();
        }
      }}
      style={{
        width: "400px",
        height: isMobile ? "auto" : "350px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:
          business.subscriptionType === "NONE" ? "#e0e0e0" : "white",

        pointerEvents: business.subscriptionType === "NONE" ? "none" : "auto",
      }}
      elevation={1}
    >
      <CardActionArea style={{ height: "100%", width: "100%", padding: "2em" }}>
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "1em" }}
        >
          <h2 style={{ margin: "0px", fontFamily: '"Poppins", sans-serif' }}>
            {business?.name}
          </h2>
          <h3 style={{ margin: "0px", fontFamily: '"Poppins", sans-serif' }}>
            {address}
          </h3>
          <h3 style={{ margin: "0px", fontFamily: '"Poppins", sans-serif' }}>
            {business?.phoneNumber}
          </h3>
          <h4 style={{ margin: "0px", fontFamily: '"Poppins", sans-serif' }}>
            Registered by {ownerName} {formatCustomDate(business.createdDate)}
          </h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              flexWrap: "wrap",
            }}
          >
            {getSubscriptionChip(business.subscriptionType)}
            {business.freeTrial && (
              <Chip style={{ width: "100px" }} label={`Free Trial`} />
            )}
            <RoleChip role={props?.role} />
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
};

export default BusinessCard;
