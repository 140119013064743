import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import _ from "lodash";

const UserDetailsScreen = () => {
  const user = {
    firstName: "Andrew",
    lastName: "White",
    email: "awhite@launchsitellc.com",
    phone: "555-555-5555",
    role: "Admin",
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "500px",
          height: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "1em",
          flexDirection: "column",
        }}
      >
        <h1>{`${user.firstName} ${user.lastName}`}</h1>
        <h3>{`role: ${user.role}`}</h3>
        <h3>{`email: ${user.email}`}</h3>
        <h3>{`phone: ${user.phone}`}</h3>
      </div>
    </div>
  );
};

export default UserDetailsScreen;
