import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import OrganizationDetails from "../../components/OrganizationDetails/OrganizationDetails";
import { getOrganizationAction } from "../../models/organization/organizationActions";
import { Button } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import LinkIcon from "@mui/icons-material/Link";
import backgroundImage from "../../shared/images/abstract-background-2.png";

import "./styles.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import _ from "lodash";
import { accountSetupCreateOrganizationAction } from "../../models/account/accountActions";
import { setPendingOrganizationInfo } from "../../models/account/accountReducer";
import BusinessSearch from "../../components/BusinessSearch/BusinessSearch";

const AccountSetupOrganization = () => {
  console.log("account setup screen");
  const navigate = useNavigate();

  const account = useSelector((state) => state.account);
  const organization = useSelector((state) => state.organizations).organization;

  const [organizationInfo, setOrganizationInfo] = useState({
    ...organization,
    address: {
      ...organization.address,
      place: {
        description: organization.address.description
          ? organization.address.description
          : "",
        place_id: organization.address.placeId,
      },
    },
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    trigger,
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("account setup screen useEffect: ", account.account);
    const orgId = account?.account?.defaultOrganization?.id;
    if (!_.isNil(orgId)) {
      console.log("getting org: ", orgId);
      dispatch(getOrganizationAction(orgId));
    }
  }, []);

  useEffect(() => {
    if (organization.id) {
      console.log("organization info: ", organizationInfo);

      setOrganizationInfo({
        ...organization,
        address: {
          ...organization.address,
          place: {
            description: organization.address.description
              ? organization.address.description
              : "",
            place_id: organization.address.placeId,
          },
        },
      });
    }
  }, [organization.id]);

  const handleNext = () => {
    dispatch(setPendingOrganizationInfo(organizationInfo));
    navigate("/account-setup/pricing");
  };

  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh", // Ensure it's at least as tall as the viewport
        display: "flex",
        alignItems: isMobile ? "flex-start" : "center", // Align to top on mobile
        justifyContent: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        margin: 0,
        padding: isMobile ? "20px 0" : 0, // Top and bottom padding on mobile
        overflowY: "auto", // Enable vertical scrolling
        overflowX: "hidden", // Disable horizontal scrolling
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: isMobile ? "80%" : "900px",
          minHeight: "900px", // Minimum height for the content
        }}
      >
        <BusinessSearch
          control={control}
          setValue={setValue}
          organizationInfo={organizationInfo}
          setOrganizationInfo={setOrganizationInfo}
        />
        <Button
          style={{ width: "200px" }}
          variant="outlined"
          onClick={handleSubmit(handleNext)}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default AccountSetupOrganization;
