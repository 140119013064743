import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { StyledDropzone } from "./StyledDropzone";
import ProfileAvatar from "../../components/ProfileAvatar/ProfileAvatar";
import { Button, CircularProgress } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountModal from "../../components/AccountModal/AccountModal";

const MyProfileScreen = () => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account).account;
  const { control, handleSubmit, errors } = useForm();
  const [file, setFile] = useState({});
  const { firstName, lastName, email } = account;
  const [updateAccount, setUpdateAccount] = useState(false);
  const loading = useSelector((state) => state.ui).loading;

  const dispatch = useDispatch();

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "2em",
          position: "absolute",
          top: "25%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div style={{ position: "absolute" }}>
            {loading && (
              <CircularProgress
                style={{ zIndex: 1000, height: 75, width: 75 }}
              />
            )}
          </div>
          <ProfileAvatar
            size={130}
            url={account?.imageUrl}
            name={account.firstName}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1 style={{ margin: "0px" }}>{`${firstName} ${lastName}`}</h1>
          <h3 style={{ margin: "0px" }}>{`${email}`}</h3>
          <div style={{ marginTop: "1em", display: "flex", columnGap: "1em" }}>
            <StyledDropzone />

            <Button
              raised
              variant="contained"
              component="label"
              style={{ textTransform: "none", backgroundColor: "black" }}
              startIcon={
                <AccountBalanceIcon style={{ height: "30px", width: "30px" }} />
              }
              onClick={() => {
                setUpdateAccount(true);
              }}
            >
              Update account information
            </Button>
          </div>
        </div>
      </div>
      <AccountModal open={updateAccount} setOpen={setUpdateAccount} />
    </div>
  );
};

export default MyProfileScreen;
