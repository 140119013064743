import { createSlice } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";

interface BotState {
  bot: any;
  bots: any[];
  metrics: any[];
  loading: boolean;
  error: any;
}

const initialState: BotState = {
  bots: [],
  bot: {},
  metrics: [],
  loading: false,
  error: null,
};

const botsSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    createBotSuccess(state, action) {
      state.bot = action.payload;
      state.bots = [...state.bots, action.payload];
      state.loading = false;
      state.error = false;
    },

    editBotSuccess(state, action) {
      state.bot = action.payload;
      state.bots = state.bots.map((bot) =>
        bot.id === action.payload.id ? action.payload : bot
      );
      state.loading = false;
      state.error = false;
    },

    sampleSmsSuccess(state, action) {
      state.loading = false;
      state.error = false;
    },

    getBotSuccess(state, action) {
      state.bot = action.payload;
    },

    getBotsSuccess(state, action) {
      state.bots = action.payload;
    },

    deleteBotSuccess(state, action) {
      state.bots = state.bots.filter((curr) => {
        return curr.id !== action.payload.id;
      });
    },

    getBotMetricsSuccess(state, action) {
      state.metrics = action.payload;
    },

    setSelectedBot(state, action) {
      state.bot = action.payload;
    },

    loading(state, action) {
      state.loading = action.payload;
    },

    failure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createBotSuccess,
  editBotSuccess,
  sampleSmsSuccess,
  getBotSuccess,
  getBotMetricsSuccess,
  getBotsSuccess,
  deleteBotSuccess,
  setSelectedBot,
  loading,
  failure,
} = botsSlice.actions;

export default botsSlice.reducer;
