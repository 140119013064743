import React, { useState } from "react";
import { Chart } from "react-google-charts";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBotMetricsCsvThunk,
  getBotMetricsThunk,
  getBotsThunk,
} from "../../models/bots/botThunks";
import BotSelection from "../../components/BotSelection/BotSelection";
import _ from "lodash";
import moment from "moment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CallVolumeChart from "../../components/CallVolumeChart/CallVolumeChart";
import { Button, Paper, Tooltip } from "@mui/material";
import backgroundImage from "../../shared/images/abstract-background-2.png";
import {
  PREMIUM,
  blurredForbiddenStyle,
  forbiddenStyle,
} from "../../config/contants";
import LockIcon from "@mui/icons-material/Lock";
import InfoIcon from "@mui/icons-material/Info";
import OrganizationSelection from "../../components/OrganizationSelection/OrganizationSelection";

const AnalyticsDashboard = (props) => {
  const dispatch = useDispatch();
  const botSlice = useSelector((state) => state.bot);
  const organizationSlice = useSelector((state) => state.organizations);
  const organization = organizationSlice.organization;
  const subscriptionType = botSlice?.bots[0]
    ? botSlice?.bots[0]?.organization?.subscriptionType
    : organization?.subscriptionType;
  const isPremium = subscriptionType === PREMIUM;

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  };

  const [startDateTime, setStartDateTime] = useState(
    moment().subtract(30, "minutes")
  );
  const [endDateTime, setEndDateTime] = useState(moment());
  const [granularity, setGranularity] = useState("day");

  // Recharts expects an array of objects where each object is a point in the chart
  const defaultChartData = [
    { time: startDateTime.valueOf(), callVolume: 0 }, // Using valueOf to get the timestamp
  ];

  const [data, setData] = useState(defaultChartData);

  useEffect(() => {
    if (organization && organization?.id) {
      dispatch(getBotsThunk(organization?.id));
    }
  }, [organization?.id]);

  useEffect(() => {
    if (botSlice?.bots?.length > 0 && !_.isNil(botSlice?.bots[0]?.id)) {
      const id = botSlice?.bots[0]?.id;

      if (startDateTime.isBefore(endDateTime)) {
        dispatch(
          getBotMetricsThunk(id, startDateTime.format(), endDateTime.format())
        );
      }
    }
  }, [startDateTime, endDateTime, organization?.id]);

  // Update granularity based on the difference between start and end dates
  useEffect(() => {
    if (startDateTime && endDateTime) {
      const diffInDays = moment(endDateTime).diff(startDateTime, "days");
      const diffInMinutes = moment(endDateTime).diff(startDateTime, "minutes");

      if (diffInMinutes <= 60) {
        // Less than or equal to 1 hour
        setGranularity("minute");
      } else if (diffInDays <= 1) {
        setGranularity("hour");
      } else if (diffInDays <= 7) {
        setGranularity("day");
      } else if (diffInDays <= 30) {
        setGranularity("week");
      } else {
        setGranularity("month");
      }
    }
  }, [startDateTime, endDateTime]);

  // Calculate aggregated data based on granularity
  useEffect(() => {
    console.log("inside of use effect");
    console.log("granularity: ", granularity);
    const metrics = botSlice?.metrics;

    console.log("metrics: ", metrics);

    if (_.isNil(metrics) || metrics.length === 0) {
      console.log("no metrics data");
      setData(defaultChartData);
    } else if (startDateTime && endDateTime) {
      const aggregatedData = {};

      metrics.forEach((call) => {
        const callTime = moment(call.callStartedTime);
        let interval;

        if (granularity === "minute") {
          interval = callTime.format("YYYY-MM-DD HH:mm");
        } else {
          interval = callTime.startOf(granularity).format("YYYY-MM-DD HH:mm");
        }

        if (!aggregatedData[interval]) {
          aggregatedData[interval] = 1;
        } else {
          aggregatedData[interval]++;
        }
      });

      const transformedData = Object.entries(aggregatedData).map(
        ([time, volume]) => {
          // Parse the time string to a moment object and convert it to a timestamp
          // This assumes that 'time' is a string that moment can parse. If it's already a moment object, you can directly use `time.valueOf()`
          const timestamp = moment(time).valueOf();

          // Return an object with the timestamp and call volume
          return { time: timestamp, callVolume: volume };
        }
      );

      setData(transformedData);
    }
  }, [startDateTime, endDateTime, granularity, botSlice?.metrics]);

  useEffect(() => {
    console.log("data: ", data);
  }, [data]);

  const getAverageCallDuration = (metrics) => {
    if (!metrics || metrics.length === 0) {
      return "0 minutes 0 seconds";
    }

    const totalDuration = metrics.reduce(
      (sum, metric) => sum + metric.callDuration,
      0
    );
    const averageDuration = Math.round(totalDuration / metrics.length);

    const minutes = Math.floor(averageDuration / 60);
    const seconds = averageDuration % 60;

    return `${minutes} minute${minutes !== 1 ? "s" : ""} ${seconds} second${
      seconds !== 1 ? "s" : ""
    }`;
  };

  const getDeflectionRate = (metrics) => {
    if (!metrics || metrics.length === 0) {
      return "N/A";
    }

    const totalNumCalls = metrics.length;
    let totalNumTransfers = 0;

    metrics.forEach((metric) => {
      if (metric.transfer) {
        totalNumTransfers += 1;
      }
    });

    // Calculate the deflection rate
    const deflectionRate =
      ((totalNumCalls - totalNumTransfers) / totalNumCalls) * 100;

    // Check if the deflection rate is a whole number
    if (deflectionRate % 1 === 0) {
      return deflectionRate + "%"; // Return as a whole number
    } else {
      return deflectionRate.toFixed(2) + "%"; // Return with two decimal places
    }
  };

  const totalCallsContent = (
    <Paper
      style={{
        width: "220px",
        height: "120px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
      }}
      elevation={1}
    >
      {!isPremium && (
        <div style={overlayStyle}>
          <LockIcon style={{ color: "grey" }} />
        </div>
      )}
      <div>
        <h1
          style={
            !isPremium
              ? { ...blurredForbiddenStyle, margin: "0px" }
              : { margin: "0px" }
          }
        >
          {!_.isNil(botSlice.metrics) && botSlice.metrics.length > 0
            ? botSlice.metrics.length
            : 0}
        </h1>
        <h4
          style={
            !isPremium
              ? { color: "lightGray", margin: "0px" }
              : { margin: "0px" }
          }
        >
          Total Calls
        </h4>
      </div>
    </Paper>
  );

  const averageCallDurationContent = (
    <Paper
      style={{
        width: "220px",
        height: "120px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
      }}
      elevation={1}
    >
      {!isPremium && (
        <div style={overlayStyle}>
          <LockIcon style={{ color: "grey" }} />
        </div>
      )}
      <div>
        <h3
          style={
            !isPremium
              ? {
                  ...blurredForbiddenStyle,
                  margin: "0px",
                  marginBottom: "16px",
                }
              : { margin: "0px" }
          }
        >{`${getAverageCallDuration(botSlice.metrics)}`}</h3>
        <h4
          style={
            !isPremium
              ? { color: "lightGray", margin: "0px" }
              : { margin: "0px" }
          }
        >
          {"Average Call Duration"}
        </h4>
      </div>
    </Paper>
  );

  const deflectionRateContent = (
    <Paper
      style={{
        width: "220px",
        height: "120px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
      }}
      elevation={1}
    >
      {!isPremium && (
        <div style={overlayStyle}>
          <LockIcon style={{ color: "grey" }} />
        </div>
      )}
      <div>
        <h1
          style={
            !isPremium
              ? {
                  ...blurredForbiddenStyle,
                  margin: "0px",
                  marginBottom: "16px",
                }
              : { margin: "0px" }
          }
        >{`${getDeflectionRate(botSlice.metrics)}`}</h1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4
            style={
              !isPremium
                ? { color: "lightGray", margin: "0px" }
                : { margin: "0px" }
            }
          >
            {"Deflection Rate"}
          </h4>
          <Tooltip title="This is the percentage of calls that were deflected, i.e., did not transfer to a human. Higher deflection rates indicate better bot performance.">
            <InfoIcon style={{ fontSize: 25, marginLeft: 5, color: "grey" }} />
          </Tooltip>
        </div>
      </div>
    </Paper>
  );

  const exportToCsvContent = (
    <Paper
      style={
        !isPremium
          ? {
              width: "220px",
              height: "120px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }
          : {
              width: "220px",
              height: "120px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
      }
      elevation={1}
    >
      {!isPremium && (
        <div style={overlayStyle}>
          <LockIcon style={{ color: "grey" }} />
        </div>
      )}
      <Button
        disabled={!isPremium}
        onClick={() => {
          dispatch(
            getBotMetricsCsvThunk(
              botSlice?.bot?.id,
              startDateTime.format(),
              endDateTime.format()
            )
          );
        }}
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          zIndex: 0,
          textTransform: "none",
          fontSize: "16px",
          fontWeight: "normal",
        }}
      >
        <div>
          <>
            <CloudDownloadIcon style={{ marginBottom: "16px" }} />
            <h4 style={{ margin: "0px" }}>Export data to csv</h4>
          </>
        </div>
      </Button>
    </Paper>
  );

  const wrapWithTooltipIfNotPremium = (component, tooltipText) => {
    return !isPremium ? (
      <Tooltip title={tooltipText}>{component}</Tooltip>
    ) : (
      component
    );
  };

  const totalCallsContentWithTooltip = wrapWithTooltipIfNotPremium(
    totalCallsContent,
    "You must upgrade your subscription to view this content"
  );

  const averageCallDurationContentWithTooltip = wrapWithTooltipIfNotPremium(
    averageCallDurationContent,
    "You must upgrade your subscription to view this content"
  );

  const deflectionRateContentWithTooltip = wrapWithTooltipIfNotPremium(
    deflectionRateContent,
    "You must upgrade your subscription to view this content"
  );

  const exportToCsvContentWithTooltip = wrapWithTooltipIfNotPremium(
    exportToCsvContent,
    "You must upgrade your subscription to view this content"
  );

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        margin: 0,
        padding: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "75%",
          padding: "5em",
          flexDirection: "column",
        }}
      >
        <div style={{ marginTop: "3em", marginBottom: "0em" }}>
          <div style={{ width: "25%", marginBottom: "2em" }}>
            <OrganizationSelection />
          </div>

          {subscriptionType === PREMIUM ? (
            <>
              <h1 style={{ margin: "0px" }}>
                {botSlice?.bots[0]?.name
                  ? botSlice?.bots[0].name
                  : "Create a bot to access customer call data"}
              </h1>
              <h4 style={{ margin: "0px" }}>
                {botSlice?.bots[0]?.name
                  ? "Analytics Summary"
                  : "No bot exists for this location"}
              </h4>
            </>
          ) : (
            <>
              <h1 style={{ margin: "0px" }}>
                {botSlice?.bots[0]?.name
                  ? botSlice?.bots[0].name
                  : "You need a Premium subscription to access customer call data"}
              </h1>
              <h4 style={{ margin: "0px" }}>
                {botSlice?.bots[0]?.name
                  ? "Analytics Summary"
                  : "No bot exists for this location"}
              </h4>
            </>
          )}

          <div
            style={{
              marginTop: "2em",
              display: "flex",
              flexDirection: "row",
              width: "70%",
              rowGap: "2em",
              columnGap: "2em",
              flexWrap: "wrap",
            }}
          >
            {totalCallsContentWithTooltip}
            {averageCallDurationContentWithTooltip}
            {deflectionRateContentWithTooltip}
            {exportToCsvContentWithTooltip}
          </div>
        </div>

        <CallVolumeChart
          startDateTime={startDateTime}
          endDateTime={endDateTime}
          granularity={granularity}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          data={data}
        />
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
