import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import HorizontalLinearStepper from "./HorizontalLinearStepper";
import { PersonalDetailsStep } from "./PersonalDetailsStep";

import { ROLE_ADMIN, ROLE_INVITED_USER } from "../../config/contants";
import {
  accountSetupChangePasswordAction,
  accountSetupCreateOrganizationAction,
  accountSetupPersonalInfoAction,
  completeAccountSetupAction,
  getAccountAction,
} from "../../models/account/accountActions";
import { ChangePasswordStep } from "./ChangePasswordStep";
import _ from "lodash";
import { Box } from "@mui/system";
import TermsAndConditions from "../../components/TermsAndConditions/TermsAndConditions";
import { termsText } from "./TermsText";
import SetupMFA from "../SetupMFA/SetupMFA";
import {
  setupAccountStepBack,
  setupAccountStepForward,
} from "../../models/account/accountReducer";
import { makeStyles } from "@mui/styles";
import backgroundImage from "../../shared/images/abstract-background-2.png";

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80%",
      margin: "0px",
    },
  },
  [theme.breakpoints.up("md")]: {
    panel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "500px",
      height: "500px",
      marginTop: "2em",
    },
  },
}));

const AccountSetupInvitedUser = () => {
  console.log("account setup screen");
  const navigate = useNavigate();

  const account = useSelector((state) => state.account);
  const [personalInfo, setPersonalInfo] = React.useState({
    id: account?.account?.id,
    email: account?.account?.email,
    login: account?.account?.email,
    activated: account?.account?.activated,
    firstName: "",
    lastName: "",
  });

  const classes = useStyles();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    trigger,
  } = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("account setup changed: ", account?.account?.accountSetup);
    if (account && account.account && account.account.accountSetup) {
      console.log("the account is setup");
      navigate("/account-setup/success");
    }
  }, [account?.account?.accountSetup]);

  const invited_user_steps = [
    {
      title: "Password update required",
      subTitle: "Please update your password",
      handleSubmit: () => {
        dispatch(
          accountSetupChangePasswordAction({
            currentPassword: currentPassword,
            newPassword: newPassword,
          })
        );
      },
      panel: (
        <div className={classes.panel}>
          <ChangePasswordStep
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            currentPassword={currentPassword}
            setCurrentPassword={setCurrentPassword}
            confirmNewPassword={confirmNewPassword}
            setConfirmNewPassword={setConfirmNewPassword}
            control={control}
          />
        </div>
      ),
    },
    {
      title: "Account Details",
      subTitle: "Please enter your own personal information",
      handleSubmit: () => {
        console.log("the personal info: ", personalInfo);
        dispatch(accountSetupPersonalInfoAction(personalInfo));
        // dispatch(completeAccountSetupAction());
      },
      panel: (
        <div className={classes.panel}>
          <PersonalDetailsStep
            personalInfo={personalInfo}
            setPersonalInfo={setPersonalInfo}
            control={control}
            setValue={setValue}
          />
        </div>
      ),
    },
  ];

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        margin: 0,
        padding: 0,
        overflow: "auto",
      }}
    >
      <div style={{ width: "90%", height: "90%" }}>
        <HorizontalLinearStepper
          canFinish={true}
          activeStep={account.accountSetup.activeStep}
          handleGoBack={() => {
            dispatch(setupAccountStepBack());
          }}
          stepperTitle={<h1 style={{ margin: "0px" }}>Welcome</h1>}
          stepperSubtitle={<h3>Let's get your account all set up</h3>}
          // acceptedTerms={acceptedTerms}
          steps={invited_user_steps}
          control={control}
          handleSubmit={handleSubmit}
          reset={reset}
        />
      </div>
    </div>
  );
};

export default AccountSetupInvitedUser;
