import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Paper,
  InputAdornment,
  IconButton,
  Link,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  LockOutlined as LockIcon,
  EmailOutlined as EmailIcon,
} from "@mui/icons-material";

import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { GoogleLogin } from "@react-oauth/google";
import {
  credentialsLoginAction,
  ssoLoginAction,
} from "../../models/account/accountActions";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
import logo from "../../shared/images/vcai-logo2.png";
import backgroundImage from "../../shared/images/login-bg.png";
import { persistor } from "../../models/store";
import useRedirect from "../../shared/useRedirect";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const ui = useSelector((state) => state.ui);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onVerifyRecaptcha = useCallback((token) => {
    setRecaptchaToken(token);
  });

  const handleCredentialsLogin = () => {
    dispatch(credentialsLoginAction(email, password));
  };

  const responseGoogle = (response) => {
    console.log("google response: ", response);
    dispatch(ssoLoginAction(response.credential));
  };

  useEffect(() => {
    persistor.purge();
  }, []);

  useRedirect();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {ui.loading && (
        <div
          style={{
            backgroundColor: "black",
            opacity: "0.5",
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: 1000,
          }}
        >
          <CircularProgress />
        </div>
      )}

      <Container
        maxWidth={false}
        sx={{
          height: isMobile ? "auto" : "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100vw",
          margin: 0,
          padding: 0,
        }}
      >
        <Paper
          elevation={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 4,
            margin: "1em",
            borderRadius: 0,
            maxHeight: isMobile ? "none" : "850px",
            maxWidth: { md: "500px" },
            minWidth: { md: "500px" },
            backgroundColor: "rgba(255, 255, 255, 0.85)",
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Company Logo"
            sx={{
              height: "100px",
              mb: 2,
            }}
          />
          <Typography
            variant="h5"
            component="h1"
            sx={{ mb: 3, display: "flex", alignItems: "center" }}
          >
            <VpnKeyIcon sx={{ mr: 1 }} />
            Login
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(handleCredentialsLogin)}
            noValidate
            sx={{ width: "100%" }}
          >
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Password"
              type={showPassword ? "text" : "password"}
              name="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <ReCAPTCHA
              sitekey="6LcffO4lAAAAAC3A0Hdn7Je9-u6Qi6XDotmoG2Ze"
              onChange={onVerifyRecaptcha}
              sx={{ my: 2 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!recaptchaToken}
              sx={{
                mt: 2,
                mb: 1,
                bgcolor: "primary.main",
                "&:disabled": {
                  bgcolor: "grey.500",
                },
              }}
            >
              Sign In
            </Button>
            <Link
              href="/forgot-password"
              variant="body2"
              sx={{ display: "block", mt: 1 }}
            >
              Forgot password?
            </Link>
          </Box>
          <Divider sx={{ width: "100%", my: 2 }}>OR</Divider>
          <GoogleLogin
            onSuccess={responseGoogle}
            onError={() => console.log("Login Failed")}
            sx={{ mb: 2 }}
          />
          <Link
            href="/sign-up"
            variant="body2"
            sx={{ fontWeight: "bold", my: 2 }}
          >
            New to the Platform? Sign Up
          </Link>
          <Box mt={5} sx={{ textAlign: "center" }}>
            <Typography variant="body2" color="textSecondary">
              © {moment().year()} Virtual Contact AI
            </Typography>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default LoginScreen;
