import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Button, Typography, Container, Paper, Link } from "@mui/material";
import { resetPasswordAction } from "../../../models/account/accountActions";
import { useLocation } from "react-router-dom";
import { CreatePassword } from "../../../components/Form/Form";
import logo from "../../../shared/images/vcai-logo2.png";
import backgroundImage from "../../../shared/images/login-bg.png";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";

const ResetPasswordScreen = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { state } = useLocation();
  const { email, key } = state;
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onVerifyRecaptcha = useCallback((token) => {
    setRecaptchaToken(token);
  });

  const handleCompletePasswordReset = () => {
    dispatch(resetPasswordAction({ email, key, newPassword: password }));
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        p: 0,
        m: 0,
      }}
    >
      <Paper
        elevation={6}
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 0,
          maxWidth: { md: 400 },
          width: isMobile ? "80%" : "400px",
          backgroundColor: "rgba(255, 255, 255, 0.85)",
        }}
      >
        <Box
          component="img"
          src={logo}
          alt="Company Logo"
          sx={{ height: "100px", mb: 2 }}
        />
        <Typography variant="h5" sx={{ mb: 2 }}>
          Password Reset
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(handleCompletePasswordReset)}
          noValidate
          sx={{ width: "100%" }}
        >
          <CreatePassword
            control={control}
            password={password}
            confirmPassword={confirmPassword}
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
          />
          <ReCAPTCHA
            sitekey="6LcffO4lAAAAAC3A0Hdn7Je9-u6Qi6XDotmoG2Ze" // Replace with your actual site key
            onChange={onVerifyRecaptcha}
            sx={{ my: 2 }}
          />
          <Button
            disabled={!recaptchaToken || password !== confirmPassword}
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 2,
              mb: 1,
              bgcolor: "primary.main",
              "&:disabled": { bgcolor: "grey.500" },
            }}
          >
            Reset Password
          </Button>
        </Box>
        <Link href="/login" variant="body2" sx={{ fontWeight: "bold", mt: 1 }}>
          Back to Sign In
        </Link>
        <Link
          href="/sign-up"
          variant="body2"
          sx={{ fontWeight: "bold", mt: 1 }}
        >
          New to the Platform? Sign Up
        </Link>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 5 }}>
          © {moment().year()} Virtual Contact AI
        </Typography>
      </Paper>
    </Container>
  );
};

export default ResetPasswordScreen;
