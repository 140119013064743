import { Autocomplete, TextField } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationAction,
  getOrganizationsAction,
} from "../../models/organization/organizationActions";
import { setSelectedOrganization } from "../../models/organization/organizationReducer";

const OrganizationSelection = () => {
  const organizationSlice = useSelector((state) => state.organizations);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("the org.org = ", organizationSlice.organization);

    dispatch(getOrganizationsAction());
  }, []);

  console.log("organizations: ", organizationSlice.organizations);

  return (
    <Autocomplete
      options={organizationSlice.organizations}
      getOptionLabel={(option) => option.name}
      defaultValue={
        organizationSlice?.organization ? organizationSlice.organization : ""
      }
      value={
        organizationSlice?.organization ? organizationSlice.organization : ""
      }
      onChange={(event, newValue) => {
        console.log("the newValue: ", newValue);
        dispatch(getOrganizationAction(newValue.id));
      }}
      autoSelect
      renderInput={(params) => (
        <TextField {...params} label="Organization" variant="standard" />
      )}
    />
  );
};

export default OrganizationSelection;
