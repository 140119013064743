import { CompatClient } from "@stomp/stompjs";
import { createSlice } from "@reduxjs/toolkit";

export enum OauthType {
  Login = "Login",
  Signup = "Sign up",
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  address: { city: string; state: string; zip: number };
  phone: string;
  freeTrialUsed: boolean;
}

export interface AccountState {
  accessToken: string | null;
  isSignedIn: boolean;
  resetPasswordData: any;

  accountSetup: { activeStep: number };

  account: any;
  error: string | null;
  resetKey: string | null;
  pushToken: string | null;
  stompClient: CompatClient | null;
  resetPasswordEmail: string | null;
  pendingOrganizationInfo: any;
}

const initialState: AccountState = {
  resetPasswordData: null,
  accessToken: null,
  isSignedIn: false,
  account: {},
  accountSetup: { activeStep: 0 },
  error: null,
  resetKey: null,
  pushToken: null,
  stompClient: null,
  resetPasswordEmail: "",
  pendingOrganizationInfo: {},
};

export const DISCONNECT_STOMP = "DISCONNECT_STOMP";

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    loading: (state) => {
      state.error = null;
    },
    disconnectStomp: (state) => {
      const client = state.stompClient;
      console.log("trying to disconnect stomp...");
      if (client != null) {
        client.disconnect();
        console.log("DISCONNECTED");
      }
      state.error = null;
      state.stompClient = null;
    },
    resendActivationEmailSuccess: (state) => {
      state.error = null;
    },
    logoutSuccess: (state) => {
      state.isSignedIn = false;
      state.account = null;
      state.accessToken = null;
      state.resetKey = null;
      state.error = null;
      state.stompClient = null;
    },
    credentialsLoginRequest: (state) => {
      state.error = null;
    },
    googleLoginRequest: (state) => {
      state.error = null;
    },
    credentialsSignupRequest: (state) => {
      state.error = null;
    },
    googleSignupRequest: (state) => {
      state.error = null;
    },
    getAccountRequest: (state) => {
      state.error = null;
    },
    getAccountSuccess: (state, action) => {
      state.account = { ...action.payload };
      state.error = null;
      state.isSignedIn = true;
    },
    addSubscriptionAuthoritySuccess: (state, action) => {
      state.account = {
        ...state.account,
        authorities: [...action.payload.authorities],
      };
      state.error = null;
    },
    setPendingOrganizationInfo: (state, action) => {
      state.pendingOrganizationInfo = action.payload;
    },
    sendSMSCodeSuccess: (state) => {
      state.error = null;
    },
    verifySMSCodeSuccess: (state, action) => {
      state.account = action.payload;
      state.error = null;
    },
    activateAccountRequest: (state) => {
      state.error = null;
    },
    activateAccountSuccess: (state, action) => {
      state.account = action.payload;
    },
    forgotPasswordInitRequest: (state, action) => {
      state.error = null;
    },
    forgotPasswordInitSuccess: (state, action) => {
      state.error = null;
      state.resetPasswordEmail = action.payload;
    },
    forgotPasswordVerifySuccess: (state, action) => {
      state.resetPasswordData = action.payload;
      state.error = null;
    },
    forgotPasswordVerifyRequest: (state) => {
      state.error = null;
    },
    resetPasswordRequest: (state) => {
      state.error = null;
    },
    updateAccountSuccess: (state, action) => {
      state.account = action.payload;
      state.error = null;
    },
    changePasswordSuccess: (state, action) => {
      state.account = action.payload;
      state.error = null;
    },
    accountSetupChangePasswordSuccess: (state, action) => {
      state.account = action.payload;
      state.error = null;
      state.accountSetup.activeStep += 1;
    },
    accountSetupPersonalInfoSuccess: (state, action) => {
      state.account = action.payload;
      state.error = null;
      state.accountSetup.activeStep += 1;
    },
    accountSetupVerifySMSSuccess: (state, action) => {
      state.account = action.payload;
      state.error = null;
      state.accountSetup.activeStep += 1;
    },
    accountSetupCreateOrganizationSuccess: (state, action) => {
      state.accountSetup.activeStep += 1;
      state.error = null;
    },
    setupAccountStepBack: (state) => {
      state.accountSetup.activeStep =
        state.accountSetup.activeStep === 0
          ? 0
          : state.accountSetup.activeStep - 1;
    },
    setupAccountStepForward: (state) => {
      state.accountSetup.activeStep += 1;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
      state.error = null;
    },
    signupSuccess: (state, action) => {
      state.account = action.payload;
    },
    registerForPushNotificationsSuccess: (state, action) => {
      state.pushToken = action.payload;
      state.error = null;
    },
    registerForWebsocketNotificationsSuccess: (state, action) => {
      state.stompClient = action.payload;
      state.error = null;
    },
    completeAccountSetupSuccess: (state, action) => {
      state.account = action.payload;
      state.accountSetup.activeStep += 1;
    },
    changeProfilePictureSuccess: (state, action) => {
      state.account = { ...state.account, profilePicture: action.payload };
      state.error = null;
    },
  },
});

export const {
  loading,
  disconnectStomp,
  resendActivationEmailSuccess,
  logoutSuccess,
  credentialsLoginRequest,
  googleLoginRequest,
  credentialsSignupRequest,
  googleSignupRequest,
  getAccountRequest,
  getAccountSuccess,
  addSubscriptionAuthoritySuccess,
  sendSMSCodeSuccess,
  setPendingOrganizationInfo,
  verifySMSCodeSuccess,
  activateAccountRequest,
  activateAccountSuccess,
  forgotPasswordInitRequest,
  forgotPasswordInitSuccess,
  forgotPasswordVerifySuccess,
  forgotPasswordVerifyRequest,
  resetPasswordRequest,
  updateAccountSuccess,
  changePasswordSuccess,
  accountSetupChangePasswordSuccess,
  accountSetupPersonalInfoSuccess,
  accountSetupVerifySMSSuccess,
  accountSetupCreateOrganizationSuccess,
  setupAccountStepBack,
  setupAccountStepForward,
  setAccessToken,
  signupSuccess,
  registerForPushNotificationsSuccess,
  registerForWebsocketNotificationsSuccess,
  completeAccountSetupSuccess,
  changeProfilePictureSuccess,
} = accountSlice.actions;

export default accountSlice.reducer;
