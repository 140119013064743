import axios from "axios";
import { applicationJson, JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

export const getOrganization = async (id) => {
  return axiosInstance.get(`${ROOT_URL}api/organizations/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getPlaceDetails = async (placeId) => {
  const apiKey = "AIzaSyAEojKIicDgs8ffwPe_sOv81vRfIrpr_4o";

  return axios.get(
    `https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&fields=opening_hours&key=${apiKey}`
  );
};

export const getOrganizations = async () => {
  return axiosInstance.get(`${ROOT_URL}api/organizations`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getOwnedOrganizations = async () => {
  return axiosInstance.get(`${ROOT_URL}api/organizations/owned`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const createOrganization = async (organization, setDefault) => {
  return axiosInstance.post(
    `${ROOT_URL}api/organizations?set-default=${setDefault}`,
    organization,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const createOrganizationPlace = async (organization, setDefault) => {
  return axiosInstance.post(
    `${ROOT_URL}api/organizations/place?set-default=${setDefault}`,
    organization,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const updateOrganization = async (organizationId, organization) => {
  return axiosInstance.put(
    `${ROOT_URL}api/organizations/${organizationId}`,
    organization,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const addOrganizationAchAccount = async (organizationId, usioInfo) => {
  return axiosInstance.put(
    `${ROOT_URL}api/organizations/${organizationId}/add-ach`,
    usioInfo,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const addOrganizationBankAccount = async (
  organizationId,
  bankAccount
) => {
  return axiosInstance.put(
    `${ROOT_URL}api/organizations/${organizationId}/add-bank-account`,
    bankAccount,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const deleteOrganization = async (id) => {
  return axiosInstance.delete(`${ROOT_URL}api/organizations/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const sendOrganizationInvite = async (id, recipient) => {
  console.log("add organization member api");
  return axiosInstance.post(
    `${ROOT_URL}api/organizations/${id}/send-organization-invite`,
    recipient,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const removeOrganizationMember = async (id, user) => {
  return axiosInstance.post(
    `${ROOT_URL}api/organizations/${id}/remove-member`,
    user,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const updateOrganizationUserPermissions = async (organizationUser) => {
  return axiosInstance.put(
    `${ROOT_URL}api/organization-users/${organizationUser.id}`,
    organizationUser,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getCurrentAccountOrganizationUser = async (organizationId) => {
  return axiosInstance.get(
    `${ROOT_URL}api/organization-users/current?organizationId=${organizationId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const getOrganizationKPIs = (id) => {
  console.log(`count organization KPIs with id: ${id}`);

  return axiosInstance.get(`${ROOT_URL}api/organizations/${id}/kpis`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getOrganizationPendingInvites = (id) => {
  console.log(`count organization pending invites: ${id}`);

  return axiosInstance.get(
    `${ROOT_URL}api/organizations/${id}/pending-invites`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};
