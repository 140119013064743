import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Typography,
  Container,
  Paper,
  InputAdornment,
  IconButton,
  Link,
  CircularProgress,
} from "@mui/material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import {
  forgotPasswordInitAction,
  forgotPasswordVerifyAction,
  resendActivationEmailAction,
} from "../../../models/account/accountActions";
import { useLocation, useNavigate } from "react-router-dom";
import { PinPad } from "../../../components/Form/Form";
import logo from "../../../shared/images/vcai-logo2.png";
import backgroundImage from "../../../shared/images/login-bg.png";
import { useDidMountEffect } from "../../../shared/commonUtils";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";

const ForgotPasswordVerificationScreen = (props) => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);

  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [pins, setPins] = useState([]);

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const ui = useSelector((state) => state.ui);

  const onVerifyRecaptcha = useCallback((token) => {
    setRecaptchaToken(token);
  });

  const handleSendCode = () => {
    dispatch(forgotPasswordInitAction(account?.resetPasswordEmail));
  };

  const handleResetPasswordVerification = () => {
    dispatch(
      forgotPasswordVerifyAction(pins.join(""), account?.resetPasswordEmail)
    );
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useDidMountEffect(() => {
    if (
      account &&
      account.resetPasswordData &&
      account.resetPasswordData.email &&
      account.resetPasswordData.key
    ) {
      navigate("/reset-password", { state: account.resetPasswordData });
    }
  }, [account?.resetPasswordData]);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          p: 0,
          m: 0,
        }}
      >
        <Paper
          elevation={6}
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 0,
            maxWidth: { md: 400 },
            width: isMobile ? "80%" : "400px",
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            boxShadow: 24,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Box
              component="img"
              src={logo}
              alt="Company Logo"
              sx={{ height: "100px", mb: 2 }}
            />
            <Typography
              variant="h5"
              component="h1"
              sx={{ mb: 3, display: "flex", alignItems: "center" }}
            >
              Please verify your reset code
            </Typography>

            <Typography sx={{ mb: 3 }}>
              We sent a 6-digit verification code to your email address
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit(handleResetPasswordVerification)}
              noValidate
              sx={{ width: "100%", mt: 2 }}
            >
              <PinPad
                pinLength={6}
                pins={pins}
                setPins={setPins}
                control={control}
                errors={errors}
              />
              <ReCAPTCHA
                sitekey="6LcffO4lAAAAAC3A0Hdn7Je9-u6Qi6XDotmoG2Ze"
                onChange={onVerifyRecaptcha}
                sx={{ my: 2 }}
              />
              <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                <Button
                  disabled={!recaptchaToken}
                  variant="outlined"
                  fullWidth
                  onClick={handleSendCode}
                >
                  Send New Code
                </Button>
                <Button
                  disabled={!recaptchaToken || pins.length < 6}
                  fullWidth
                  variant="contained"
                  type="submit"
                >
                  Verify
                </Button>
              </Box>
            </Box>
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Link
                href="/login"
                variant="body2"
                sx={{ fontWeight: "bold", mt: 1 }}
              >
                Back to Sign In
              </Link>
              <Link
                href="/sign-up"
                variant="body2"
                sx={{ fontWeight: "bold", mt: 1 }}
              >
                New to the Platform? Sign Up
              </Link>
              <Box sx={{ textAlign: "center", mt: 5 }}>
                <Typography variant="body2" color="textSecondary">
                  © {moment().year()} Virtual Contact AI
                </Typography>
              </Box>
            </div>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default ForgotPasswordVerificationScreen;
