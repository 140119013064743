import React, { useState } from "react";

import {
  AddressWithZip,
  CreatePassword,
  EnterPassword,
  FirstName,
  LastName,
  PhoneNumber,
} from "../../components/Form/Form";
import _ from "lodash";
import { useSelector } from "react-redux";

export const ChangePasswordStep = (props) => {
  const {
    control,
    currentPassword,
    setCurrentPassword,
    setNewPassword,
    newPassword,
    confirmNewPassword,
    setConfirmNewPassword,
  } = props;

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <p>Current Password</p>
      <EnterPassword
        control={control}
        password={currentPassword}
        setPassword={(password) => {
          setCurrentPassword(password);
        }}
      />
      <p>New Password</p>
      <CreatePassword
        control={control}
        password={newPassword}
        confirmPassword={confirmNewPassword}
        setPassword={(password) => {
          setNewPassword(password);
        }}
        setConfirmPassword={(password) => {
          setConfirmNewPassword(password);
        }}
      />
    </div>
  );
};
