import { addUserSubscriptionAuthority } from "../account/accountReducer";
import { failure, loading, toastFailure, toastSuccess } from "../uiReducer";
import {
  createOrganization,
  createOrganizationPlace,
  deleteOrganization,
  getCurrentAccountOrganizationUser,
  getOrganization,
  getOrganizationKPIs,
  getOrganizationPendingInvites,
  getOrganizations,
  getOwnedOrganizations,
  getPlaceDetails,
  removeOrganizationMember,
  sendOrganizationInvite,
  updateOrganization,
  updateOrganizationUserPermissions,
} from "./organizationApi";
import {
  getOrganizationSuccess,
  getOrganizationsSuccess,
  createOrganizationSuccess,
  removeMemberSuccess,
  deleteOrganizationSuccess,
  sendOrganizationInviteSuccess,
  updateOrganizationMemberPermissionSuccess,
  getCurrentAccountOrganizationUserSuccess,
  updateOrganizationSuccess,
  getOwnedOrganizationsSuccess,
  getOrganizationKPIsSuccess,
  getOrganizationPendingInvitesSuccess,
  getPlaceDetailsSuccess,
} from "./organizationReducer";

export const getPlaceDetailsAction = (placeId) => async (dispatch) => {
  console.log("get place details action for place: ", placeId);
  dispatch(loading(true));

  try {
    const response = await getPlaceDetails(placeId);

    console.log("place details: ", response.data);

    dispatch(getPlaceDetailsSuccess(response.data));
  } catch (error) {
    console.log("inside of get place details error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const getOrganizationsAction = () => async (dispatch) => {
  console.log("get organizations action");
  dispatch(loading(true));

  try {
    const response = await getOrganizations();

    console.log("the organizations: ", response.data);

    dispatch(getOrganizationsSuccess(response.data));
  } catch (error) {
    console.log("inside of get organizations error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const getOwnedOrganizationsAction = () => async (dispatch) => {
  console.log("get owned organizations action");
  dispatch(loading(true));

  try {
    const response = await getOwnedOrganizations();

    console.log("the organizations: ", response.data);

    dispatch(getOwnedOrganizationsSuccess(response.data));
  } catch (error) {
    console.log("inside of get organizations error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const getOrganizationAction = (id) => async (dispatch) => {
  console.log("get organization action");
  dispatch(loading(true));

  try {
    const response = await getOrganization(id);

    console.log("the organization: ", response.data);
    dispatch(getOrganizationSuccess(response.data));
  } catch (error) {
    console.log("inside of get organization error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const createOrganizationPlaceAction =
  (organization, setDefault) => async (dispatch) => {
    console.log("create organization place action: ", organization);

    dispatch(loading(true));

    try {
      const response = await createOrganizationPlace(organization, setDefault);

      console.log("the organization: ", response.data);

      dispatch(createOrganizationSuccess(response.data));
      toastSuccess("Success!", "Your organization was created");
    } catch (error) {
      console.log("inside of create organization error");
      toastFailure(error);
      dispatch(loading(false));
      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const createOrganizationAction =
  (organization, setDefault) => async (dispatch) => {
    console.log("create organization action: ", organization);

    dispatch(loading(true));

    try {
      const response = await createOrganization(organization, setDefault);

      console.log("the organization: ", response.data);

      dispatch(createOrganizationSuccess(response.data));
      toastSuccess("Success!", "Your organization was created");
    } catch (error) {
      console.log("inside of create organization error");
      toastFailure(error);
      dispatch(loading(false));
      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const updateOrganizationAction = (organization) => async (dispatch) => {
  console.log("update organization action: ", organization);

  dispatch(loading(true));

  try {
    const response = await updateOrganization(organization.id, organization);

    console.log("the updated organization: ", response.data);

    dispatch(updateOrganizationSuccess(response.data));
    toastSuccess("Success!", "Your organization was updated");
  } catch (error) {
    console.log("inside of update organization error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const deleteOrganizationAction = (id) => async (dispatch) => {
  console.log("delete organization action for organization: ", id);

  dispatch(loading(true));

  try {
    await deleteOrganization(id);

    dispatch(deleteOrganizationSuccess(id));
    toastSuccess("Success!", "The organization was deleted");
  } catch (error) {
    console.log("inside of delete organization error");
    toastFailure(error);
    dispatch(loading(false));
    dispatch(failure(error?.response?.data?.message));
  }
  dispatch(loading(false));
};

export const sendOrganizationInviteAction =
  (organizationId, user) => async (dispatch) => {
    console.log("add member action");

    dispatch(loading(true));

    try {
      const response = await sendOrganizationInvite(organizationId, user);

      console.log("send organization invite response: ", response.data);

      dispatch(sendOrganizationInviteSuccess());

      const responseOrganization = await getOrganization(organizationId);

      console.log("the organization: ", responseOrganization.data);

      dispatch(getOrganizationSuccess(responseOrganization.data));

      toastSuccess("Success!", "Your organization invitation was sent");
    } catch (error) {
      console.log("inside of add member error: ", error);
      toastFailure(error);
      dispatch(loading(false));
      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const removeMemberAction =
  (organization, user, toastRef) => async (dispatch) => {
    console.log(
      `remove member action for organization: ${organization.id}, user: ${user.id}`
    );

    dispatch(loading(true));

    try {
      const response = await removeOrganizationMember(organization.id, user);

      console.log("the organization result: ", response.data);

      dispatch(removeMemberSuccess(response.data));
      toastSuccess("Success!", "The user was removed", toastRef);
    } catch (error) {
      console.log("inside of remove member error");
      toastFailure(error, toastRef);
      dispatch(loading(false));
      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const updateOrganizationUserPermissionsAction =
  (organizationUser, toastRef) => async (dispatch) => {
    console.log("got the toastRef: ", toastRef);
    console.log("update user permissions action");
    console.log("the organization user: ", organizationUser);
    dispatch(loading(true));

    console.log("organization user DTO: ", organizationUser);

    try {
      console.log("updating organization permissions");
      const response = await updateOrganizationUserPermissions(
        organizationUser
      );
      dispatch(updateOrganizationMemberPermissionSuccess(response.data));
      console.log("update permissions response data: ", response.data);
      toastSuccess("Success!", "Privilege updated successfully", toastRef);
    } catch (error) {
      console.log("inside of update permissions error");
      console.log("error: ", error);
      toastFailure(error, toastRef);
      dispatch(loading(false));
      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const getCurrentAccountOrganizationUserAction =
  (organizationId) => async (dispatch) => {
    console.log("get current account organization user action");
    dispatch(loading(true));

    try {
      const response = await getCurrentAccountOrganizationUser(organizationId);

      console.log("the current account organization user: ", response.data);

      dispatch(getCurrentAccountOrganizationUserSuccess(response.data));
    } catch (error) {
      console.log("inside of get current account organization user error");
      toastFailure(error);
      dispatch(loading(false));
      dispatch(failure(error?.response?.data?.message));
    }
    dispatch(loading(false));
  };

export const getOrganizationKPIsAction = (orgId) => async (dispatch) => {
  console.log(`handle get organization KPIs ${orgId}`);
  dispatch(loading(true));

  try {
    const response = await getOrganizationKPIs(orgId);

    console.log("response: ", JSON.stringify(response));

    dispatch(getOrganizationKPIsSuccess(response.data));
  } catch (error) {
    toastFailure(error);
    dispatch(failure(error.response.data.message));
    dispatch(loading(false));
  }
  dispatch(loading(false));
};

export const getOrganizationPendingInvitesAction =
  (orgId) => async (dispatch) => {
    console.log(`handle get organization invites ${orgId}`);
    dispatch(loading(true));

    try {
      const response = await getOrganizationPendingInvites(orgId);

      console.log("response: ", JSON.stringify(response));

      dispatch(getOrganizationPendingInvitesSuccess(response.data));
    } catch (error) {
      toastFailure(error);
      dispatch(failure(error.response.data.message));
      dispatch(loading(false));
    }
    dispatch(loading(false));
  };
