import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EMAIL_REGEX, ROLE_USER } from "../../config/contants";
import UserDetails from "../UserDetails/UserDetails";
import { sendOrganizationInviteAction } from "../../models/organization/organizationActions";

const style = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function UserModal(props) {
  const { open, setOpen } = props;
  const account = useSelector((state) => state.account);
  const organization = useSelector((state) => state.organizations);

  const [userInfo, setUserInfo] = useState({ authorities: [ROLE_USER] });

  const { control, handleSubmit, reset } = useForm();

  const dispatch = useDispatch();

  const handleAddUser = () => {
    console.log("add user: ", userInfo);

    dispatch(
      sendOrganizationInviteAction(organization?.organization?.id, {
        ...userInfo,
        login: userInfo.email,
      })
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setUserInfo({ role: "User" });
          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <h3>Invite User</h3>

            <UserDetails
              control={control}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
            />

            <Button onClick={handleSubmit(handleAddUser)}>Submit</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
