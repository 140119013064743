import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getBotMetricsThunk } from "../../models/bots/botThunks";
import { useForm } from "react-hook-form";
import { DateSelect, DateTimeSelect } from "../Form/Form";
import Chart from "react-google-charts";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
  Label,
  ReferenceArea,
} from "recharts";
import { PREMIUM } from "../../config/contants";

import LockIcon from "@mui/icons-material/Lock";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "@mui/material/Button";
import { createCustomerPortalSessionAction } from "../../models/stripe/stripeActions";

function CallVolumeChart(props) {
  const {
    startDateTime,
    endDateTime,
    setStartDateTime,
    setEndDateTime,
    granularity,
    data,
  } = props;

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isSubmitting, isDirty, isValid, errors },
    setValue,
    reset,
  } = useForm();

  const [ticks, setTicks] = useState();

  const dispatch = useDispatch();

  const botSlice = useSelector((state) => state.bot);
  const organizationSlice = useSelector((state) => state.organizations);

  const organization = organizationSlice.organization;

  const subscriptionType = botSlice?.bots[0]
    ? botSlice?.bots[0]?.organization?.subscriptionType
    : organization?.subscriptionType;

  // Transform 'callVolume' to 'Calls' for readability
  const transformedData = data.map((item) => ({
    ...item,
    Calls: item.callVolume,
  }));

  const formatTick = (unixTime, granularity) => {
    const formats = {
      minute: "h:mm A", // e.g., 3:45 PM
      hour: "MMM D, h A", // e.g., Jan 3, 3 PM
      day: "MMM D", // e.g., Jan 3
      week: "'Week' WW", // e.g., Week 52
      month: "MMM YYYY", // e.g., Jan 2023
    };

    return moment(unixTime).format(formats[granularity]);
  };

  const generateTicks = (start, end, granularity) => {
    let startTime = moment(start).startOf(granularity); // Align to the start of the granularity period
    let endTime = moment(end).endOf(granularity); // Align to the end of the granularity period
    let ticks = [startTime.valueOf()]; // Start with the initial tick

    // Determine the tick interval based on the granularity
    const unitMap = {
      minute: "minutes",
      hour: "hours",
      day: "days",
      week: "weeks",
      month: "months",
    };

    // Prevent infinite loops
    const maxIterations = 1000;
    let iterations = 0;

    while (startTime < endTime && iterations < maxIterations) {
      startTime = startTime.clone().add(1, unitMap[granularity]);
      if (startTime <= endTime) {
        ticks.push(startTime.valueOf());
      }
      iterations++;
    }

    // Always include the end time in the ticks
    if (!ticks.includes(endTime.valueOf())) {
      ticks.push(endTime.valueOf());
    }

    return ticks;
  };

  useEffect(() => {
    const ticks = generateTicks(startDateTime, endDateTime, granularity);
    setTicks(ticks);
  }, [startDateTime, endDateTime, granularity]);

  const canViewChartData = subscriptionType === "PREMIUM";

  return (
    <div>
      <div style={{ width: "100%", marginTop: "2em", marginBottom: "2em" }}>
        <div
          style={{
            display: "flex",
            width: "80%",
            flexDirection: "row",
            columnGap: "1em",
          }}
        >
          <div style={{ display: "flex", width: "50%" }}>
            <DateTimeSelect
              disabled={!canViewChartData}
              name="startDateTime"
              label="Select Start Date"
              control={control}
              date={startDateTime}
              setDate={(value) => {
                setStartDateTime(value);
              }}
            />
          </div>
          <div style={{ display: "flex", width: "50%" }}>
            <DateTimeSelect
              disabled={!canViewChartData}
              name="endDateTime"
              label="Select End Date"
              control={control}
              date={endDateTime}
              setDate={(value) => {
                setEndDateTime(value);
              }}
            />
          </div>
        </div>
      </div>

      <div style={{ position: "relative" }}>
        <LineChart
          width={1000}
          height={400}
          data={canViewChartData ? transformedData : []}
          margin={{ top: 5, right: 30, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            dataKey="time"
            domain={["dataMin", "dataMax"]}
            tickFormatter={(unixTime) => formatTick(unixTime, granularity)}
            label={{
              value: "Call Time",
              position: "insideBottom",
              offset: -15,
            }}
            ticks={ticks}
          />
          <YAxis
            allowDecimals={false}
            allowDataOverflow={true}
            label={{
              value: "Calls Received",
              angle: -90,
              position: "insideLeft",
            }}
          />
          {canViewChartData && (
            <>
              <Tooltip
                labelFormatter={(unixTime) => moment(unixTime).format("h:mm A")}
                formatter={(value, name) => [
                  value,
                  name === "Calls" ? "Calls Received" : name,
                ]}
              />
              <Legend payload={[]} />
              <Line
                type="monotone"
                dataKey="Calls"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
                name=""
              />
            </>
          )}
        </LineChart>
        {!canViewChartData && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              fontSize: "20px",
              color: "grey",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              {" "}
              <LockIcon style={{ marginRight: "10px" }} />
              You must upgrade your subscription to see this content
            </div>

            <Button
              variant="contained"
              style={{
                backgroundColor: "#66BDA9",
                color: "white",
                textTransform: "none",
              }}
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                dispatch(createCustomerPortalSessionAction());
              }}
            >
              Upgrade Now
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CallVolumeChart;
