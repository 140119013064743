import React, { useState } from "react";

import { FirstName, FullAddress, LastName } from "../../components/Form/Form";
import _ from "lodash";

export const PersonalDetailsStep = (props) => {
  const { control, setValue, personalInfo, setPersonalInfo } = props;

  return (
    <div>
      <PersonalDetails
        control={control}
        setValue={setValue}
        personalInfo={personalInfo}
        setPersonalInfo={setPersonalInfo}
      />
    </div>
  );
};

const PersonalDetails = (props) => {
  const { personalInfo, setPersonalInfo, control, setValue } = props;

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          columnGap: "1em",
          flexDirection: "row",
        }}
      >
        <FirstName
          control={control}
          firstName={personalInfo?.firstName}
          setFirstName={(firstName) => {
            setPersonalInfo({ ...personalInfo, firstName: firstName });
          }}
        />

        <LastName
          control={control}
          lastName={personalInfo?.lastName}
          setLastName={(lastName) => {
            setPersonalInfo({ ...personalInfo, lastName: lastName });
          }}
        />
      </div>
    </div>
  );
};
