import { useSelector } from "react-redux";
import React from "react";
import Tooltip from "@mui/material/Tooltip";

const ProtectedComponent = ({ children, roles, mustBeOwner }) => {
  const account = useSelector((state) => state.account);
  const organizationSlice = useSelector((state) => state.organizations);
  const currentUserEmail = account.account.email;

  const organizationUser =
    organizationSlice?.organization?.organizationUsers.find(
      (orgUser) => orgUser.user.login === currentUserEmail
    );

  const currentUserRole = organizationUser?.role;

  console.log("organizationUser: ", organizationUser);
  console.log("currentUserRole: ", currentUserRole);

  const hasAccess = mustBeOwner
    ? organizationSlice?.organization?.owner?.login === currentUserEmail
    : roles?.includes(currentUserRole) ||
      organizationSlice?.organization?.owner?.login === currentUserEmail;

  const childWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { disabled: !hasAccess })
  );

  return (
    <Tooltip
      title={!hasAccess ? "You don't have access to this action" : ""}
      disableHoverListener={hasAccess}
    >
      <span
        style={{
          cursor: !hasAccess ? "not-allowed" : "auto",
        }}
      >
        {childWithProps}
      </span>
    </Tooltip>
  );
};

export default ProtectedComponent;
