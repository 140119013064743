import React from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { createTheme, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ThemeProvider } from "@emotion/react";
import _ from "lodash";
import { ROLE_ADMIN } from "../../config/contants";
import ProtectedComponent from "../ProtectedComponent/ProtectedComponent";

const UserTable = (props) => {
  const { data, setAddUser } = props;
  const navigate = useNavigate();

  console.log("the organization user data was: ", data);

  const normalized = data?.map((curr) => {
    const { user } = curr;

    return {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
      role: `${user?.authorities?.includes(ROLE_ADMIN) ? "Admin" : "User"}`,
      inviteStatus: curr.inviteStatus,
    };
  });

  const getMuiTheme = () =>
    createTheme({
      typography: {
        fontFamily: '"Poppins", sans-serif',
      },
      components: {
        MuiTableRow: {
          styleOverrides: {
            root: {
              cursor: "pointer",
            },
          },
        },
      },
    });

  const options = {
    filterType: "checkbox",
    download: false, // Set to false to remove the download option
    print: false, // Set to false to remove the print option
    viewColumns: false, // Disable view columns option
    filter: false, // Disable filter table option

    onRowClick: (rowData, rowMeta) => {
      //   dispatch(getMerchantById(rowData[0]));

      navigate(`user-details/${rowData[0]}-${rowData[1]}`, {
        merchantName: rowData[0],
      });
    },

    customToolbar: () => {
      return (
        <ProtectedComponent roles={[ROLE_ADMIN]}>
          <IconButton
            onClick={() => {
              setAddUser(true);
            }}
            style={{ order: -1 }}
          >
            <AddIcon />
          </IconButton>
        </ProtectedComponent>
      );
    },
    selectableRows: false,
  };

  const columns = [
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "inviteStatus",
      label: "Invite Status",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        style={{ cursor: "pointer" }}
        title={"Users"}
        data={!_.isNil(normalized) && normalized.length > 0 ? normalized : []}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
};

export default UserTable;
