import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";

import { useState } from "react";
import { useForm } from "react-hook-form";

import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import UserTable from "../../components/UserTable/UserTable";
import UserModal from "../../components/UserModal/UserModal";
import _ from "lodash";
import {
  PREMIUM,
  PREMIUM_GRADIENT,
  PRO,
  PRO_GRADIENT,
  ROLE_ADMIN,
} from "../../config/contants";
import ProtectedComponent from "../../components/ProtectedComponent/ProtectedComponent";
import BotCard from "../../components/BotCard/BotCard";
import OrganizationSelection from "../../components/OrganizationSelection/OrganizationSelection";
import BotsModal from "../../components/BotsModal/BotsModal";
import { createCustomerPortalSessionAction } from "../../models/stripe/stripeActions";
import { getBotThunk, getBotsThunk } from "../../models/bots/botThunks";
import backgroundImage from "../../shared/images/abstract-background-2.png";
import KpiComponent from "../../components/KpiComponent/KpiComponent";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupIcon from "@mui/icons-material/Group";

import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver"; // Represents voice commands
import GoogleMapComponent from "../../components/GoogleMapComponent/GoogleMapComponent";

const OrganizationDashboard = () => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const botSlice = useSelector((state) => state.bot);
  const uiSlice = useSelector((state) => state.ui);

  const organizationSlice = useSelector((state) => state.organizations);
  const organization = organizationSlice.organization;

  const [addUser, setAddUser] = useState(false);
  const [botModalOpen, setBotModalOpen] = useState(false);
  const [mode, setMode] = useState("create");

  const { control, handleSubmit, errors } = useForm();

  const dispatch = useDispatch();

  const admins = _.groupBy(organization?.organizationUsers, function (orgUser) {
    return orgUser?.user?.authorities.includes(ROLE_ADMIN);
  }).true;

  const freeTrialText = organization.freeTrial ? `(Free Trial)` : ``;

  console.log("admins: ", admins);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (organization && organization?.id) {
      dispatch(getBotsThunk(organization?.id));
    }
  }, [organization?.id]);

  const handleEditBot = () => {
    setBotModalOpen(true);
    setMode("edit");
  };

  const handleCreateBot = () => {
    setBotModalOpen(true);
    setMode("create");
  };

  const kpiData = [
    {
      title: `${admins && admins.length > 0 ? admins.length : "0"}`,
      subTitle: "Admins",
      IconComponent: AdminPanelSettingsIcon,
      iconColor: "#4caf50",
    },
    {
      title: `${
        organization &&
        organization.organizationUsers &&
        organization.organizationUsers.length > 0
          ? organization.organizationUsers.length
          : "0"
      }`,
      subTitle: "Total Members",
      IconComponent: GroupIcon,
      iconColor: "#3f51b5",
    },
  ];

  const CreateBot = ({ handleCreateBot }) => {
    return (
      <ProtectedComponent roles={[ROLE_ADMIN]}>
        <ButtonBase
          sx={{
            height: isMobile ? "220px" : "100%",
            width: isMobile ? "220px" : "300px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#F0F0F0",
            borderRadius: "0px",
            overflow: "hidden",
            "&:hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
          onClick={handleCreateBot}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RecordVoiceOverIcon sx={{ fontSize: "40px", color: "#C0C0C0" }} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" component="h3" sx={{ margin: "0px" }}>
                Create a bot
              </Typography>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton style={{ order: -1, pointerEvents: "none" }}>
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </ButtonBase>
      </ProtectedComponent>
    );
  };

  return (
    <>
      {botSlice.loading ||
        (uiSlice.loading && (
          <div
            style={{
              backgroundColor: "black",
              opacity: "0.5",
              display: "flex",
              width: "100vw",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed", // Using fixed to make it relative to the viewport
              zIndex: 2000, // Ensure this is higher than any other z-index on the page
              top: 0,
              left: 0,
            }}
          >
            <CircularProgress />
          </div>
        ))}
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100vw",
          margin: 0,
          padding: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            height: "100%",
            width: isMobile ? "80%" : "75%",
            padding: "2em",
            flexDirection: "column",
          }}
        >
          <div style={{ marginTop: "3em", marginBottom: "3em" }}>
            <div
              style={{ width: isMobile ? "100%" : "25%", marginBottom: "2em" }}
            >
              <OrganizationSelection />
            </div>
            <h1
              style={{ margin: "0px", fontSize: isMobile ? "22px" : "default" }}
            >{`${organization?.name ? organization?.name : "--"}`}</h1>
            <h3
              style={{ margin: "0px", fontSize: isMobile ? "16px" : "default" }}
            >
              Organization Dashboard
            </h3>
            <ProtectedComponent mustBeOwner>
              <Button
                style={{
                  textTransform: "none", // Sentence case

                  fontSize: "14px",
                  height: "45px",
                  borderRadius: "100px",
                  color: "black",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  marginTop: "1em",
                  background:
                    organization?.subscriptionType == PREMIUM
                      ? PREMIUM_GRADIENT
                      : organization?.subscriptionType == PRO
                      ? PRO_GRADIENT
                      : "transparent",
                }}
                onClick={() => {
                  dispatch(createCustomerPortalSessionAction());
                }}
              >
                {`Manage Subscription: ${organization.subscriptionType} ${freeTrialText}`}
              </Button>
            </ProtectedComponent>
          </div>

          <div style={{ width: "100%", height: isMobile ? "auto" : "320px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: "2em",
                marginBottom: "2em",
                justifyContent: "space-between",
              }}
            >
              <div>
                {botSlice?.bots && botSlice?.bots.length > 0 ? (
                  <BotCard
                    handleEditBot={handleEditBot}
                    bot={botSlice?.bots[0]}
                  />
                ) : (
                  <CreateBot handleCreateBot={handleCreateBot} />
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "row" : "column",
                  rowGap: "2em",
                  columnGap: isMobile ? "0px" : "2em",
                  flexWrap: "wrap",
                  justifyContent: isMobile ? "space-between" : "center",
                }}
              >
                {kpiData.map((kpi, index) => (
                  <KpiComponent
                    key={index}
                    title={kpi.title}
                    subTitle={kpi.subTitle}
                    IconComponent={kpi.IconComponent}
                    iconColor={kpi.iconColor}
                  />
                ))}
              </div>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                  backgroundColor: "lightgray",
                }}
              >
                <GoogleMapComponent
                  address={organization?.address?.description}
                />
              </div>
            </div>

            <UserTable
              setAddUser={setAddUser}
              data={organization?.organizationUsers}
            />

            <UserModal open={addUser} setOpen={setAddUser} />
            <BotsModal
              open={botModalOpen}
              setOpen={setBotModalOpen}
              mode={mode}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationDashboard;
