import { CircularProgress, Link } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAccountAction } from "../../models/account/accountActions";
import backgroundImage from "../../shared/images/abstract-background-2.png";

const AccountSetupSuccessScreen = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  const wrapperStyle = {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100vw",
    margin: 0,
    padding: 0,
    overflow: "hidden",
    flexDirection: "column",
    gap: "1em",
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    dispatch(getAccountAction());
  }, []);

  useEffect(() => {
    if (
      !_.isNil(account?.account?.defaultOrganization?.id) &&
      !_.isNil(account?.account?.firstName)
    ) {
      navigate("/");
    }
  }, [account?.account?.defaultOrganization]);

  const [errorOccurred, setErrorOccurred] = useState(false);
  const [pollingCount, setPollingCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAccountAction());
        console.log("Account fetched successfully.");

        if (pollingCount >= 6) {
          setErrorOccurred(true); // Set the error state after 30 seconds
          console.log("Error state set after 30 seconds.");
        }
      } catch (error) {
        console.error("An error occurred while fetching account:", error);
        setErrorOccurred(true);
      }
    };

    if (!errorOccurred && pollingCount < 6) {
      fetchData(); // Fetch the data immediately

      const intervalId = setInterval(() => {
        setPollingCount((prevCount) => prevCount + 1);
        fetchData(); // Fetch the data every 5 seconds
      }, 5000);

      return () => {
        clearInterval(intervalId);
      };
    } else if (pollingCount >= 6) {
      setErrorOccurred(true);
    }
  }, [dispatch, errorOccurred, pollingCount]);

  if (errorOccurred) {
    return (
      <div
        style={{
          ...wrapperStyle,
        }}
      >
        <h1 style={{ margin: "0px", fontSize: isMobile ? "24px" : "default" }}>
          {" "}
          Something's not quite right...{" "}
        </h1>

        <h2 style={{ margin: "0px", fontSize: isMobile ? "20px" : "default" }}>
          {" "}
          An error ocurred{" "}
        </h2>
        <Link style={{ fontWeight: "bold" }} href="/login" variant="body2">
          Back to login
        </Link>
      </div>
    );
  }

  return (
    <div
      style={{
        ...wrapperStyle,
      }}
    >
      <div>
        <h2>{`Thanks for signing up, ${account.account.firstName}!`}</h2>
        <p> Hang tight while we setup your dashboard </p>
      </div>
      <CircularProgress style={{ height: "100px", width: "100px" }} />
    </div>
  );
};

export default AccountSetupSuccessScreen;
