import { combineReducers } from "redux";
import accountReducer from "../models/account/accountReducer";
import notificationReducer from "./notification/notificationReducer";
import uiReducer from "./uiReducer";
import organizationReducer from "./organization/organizationReducer";
import botReducer from "./bots/botReducer";
import storageSession from "redux-persist/lib/storage/session";

const rootReducer = combineReducers({
  account: accountReducer,
  notification: notificationReducer,
  ui: uiReducer,
  organizations: organizationReducer,
  bot: botReducer,
});

export default (state, action) =>
  rootReducer(action.type === "CLEAR_REDUX" ? undefined : state, action);

// export default rootReducer;
