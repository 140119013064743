import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

export default function HorizontalLinearStepper(props) {
  const {
    steps,
    control,
    stepperTitle,
    stepperSubtitle,
    activeStep,
    canFinish,
    handleGoBack,
    handleSubmit,
  } = props;

  console.log("control: ", control);

  const handleNext = () => {
    steps[activeStep].handleSubmit();
  };

  const handleBack = () => {
    handleGoBack();
  };

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {stepperTitle}
        {stepperSubtitle}

        <div
          style={{
            display: "flex",
            width: isMobile ? "100%" : "70%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Stepper
            style={{ width: "70%", marginBottom: "2em" }}
            sx={{
              "& .MuiStepIcon-root.Mui-active": { color: "#4caf50" }, // Active step icon
              "& .MuiStepIcon-root.Mui-completed": { color: "#4caf50" }, // Completed step icons
              "& .MuiStepConnector-line": { borderColor: "#4caf50" }, // Connector lines
            }}
            activeStep={activeStep}
          >
            {steps.map((step, index) => {
              const { title, panel } = step;
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={title} {...stepProps}>
                  <StepLabel {...labelProps}>{title}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            {steps[activeStep]?.panel}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              {activeStep != 0 ? "Back" : ""}
            </Button>

            <Button
              disabled={
                activeStep === steps.length - 1 && !canFinish ? true : false
              }
              onClick={handleSubmit(handleNext)}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
}
