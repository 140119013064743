import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Button, Link, Container } from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { useSelector } from "react-redux";
import { PinPad } from "../../components/Form/Form";

import {
  activateAccountAction,
  resendActivationEmailAction,
} from "../../models/account/accountActions";
import { useDidMountEffect } from "../../shared/commonUtils";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import backgroundImage from "../../shared/images/login-bg.png";

const EmailVerifyScreen = (props) => {
  const navigate = useNavigate();
  const account = useSelector((state) => state.account);
  const [pins, setPins] = useState("");
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errors }, // here
  } = useForm();

  const handleSendCode = () => {
    const email = account?.account?.email;
    console.log("attempting resend: ", email);
    dispatch(resendActivationEmailAction(email));
  };

  const handleActivateAccount = () => {
    dispatch(activateAccountAction(pins.join("")));
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let timeoutId;

    const activated = account?.account?.activated;
    if (activated) {
      console.log("Account activated, redirecting to login...");

      // Redirect the user to the login page
      timeoutId = setTimeout(() => {
        navigate("/login");
      }, 2000);
    }

    // Cleanup function to clear the timeout
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [account?.account?.activated, navigate]);

  const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "80%" : "400px",

    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        margin: 0,
        padding: 0,
        overflow: "hidden",
      }}
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "1em",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "1000px",
              }}
            >
              <ForwardToInboxIcon
                style={{
                  height: "40px",
                  width: "40px",
                  color: "white",
                }}
              />
            </div>

            <h2 style={{ margin: "0em" }}>Please verify your email</h2>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              rowGap: "1em",
              height: "300px",
            }}
          >
            <p style={{ margin: "0px" }}>
              We sent a 6 digit verification code to your email address
            </p>
            <PinPad
              pinLength={6}
              pins={pins}
              setPins={setPins}
              control={control}
              errors={errors}
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                columnGap: "1em",
              }}
            >
              <Button onClick={handleSendCode}>Send New Code</Button>
              <Button
                disabled={!isDirty || !isValid}
                variant="outlined"
                onClick={handleSubmit(handleActivateAccount)}
              >
                Verify
              </Button>
            </div>
            <Link
              style={{ fontWeight: "bold", marginTop: "1em" }}
              href="/login"
              variant="body2"
            >
              Back to login
            </Link>
          </div>
        </div>
      </Box>
    </Container>
  );
};

export default EmailVerifyScreen;
