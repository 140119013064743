import { Button, Divider, Popover, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsAction } from "../../models/notification/notificationActions";
import {
  resetNotificationsBellAction,
  openAllUnopenedNotificationsAction,
  acceptOrganizationInviteAction,
  rejectOrganizationInviteAction,
} from "../../models/notification/notificationActions";
import { getUnopenedNotificationsCountAction } from "../../models/notification/notificationActions";

import {
  NotificationAcknowlegement,
  NotificationType,
} from "../../models/notification/notificationReducer";
import { getElapsedTimeString } from "../../shared/commonUtils";
import { NotificationBell } from "../NotificationBell/NotificationBell";

import { InviteAcceptedNotification } from "./InviteAcceptedNotification";
import { OrganizationInviteNotification } from "./OrganizationInviteNotification";
import _ from "lodash";

export const Notifications = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const account = useSelector((state) => state.account);
  const notification = useSelector((state) => state.notification);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(resetNotificationsBellAction());
    dispatch(openAllUnopenedNotificationsAction());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (account && account.account && account.account.login) {
      dispatch(getUnopenedNotificationsCountAction());
    }
  }, [account.account]);

  useEffect(() => {
    dispatch(getNotificationsAction());
  }, []);

  useEffect(() => {
    if (notification && notification.unopenedNotificationsCount > 0) {
      dispatch(getNotificationsAction());
    }
  }, [notification.unopenedNotificationsCount]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const returnNotification = (item) => {
    console.log("notification item: ", item);
    switch (item.notificationType) {
      case NotificationType.ORGANIZATION_INVITE_REQUEST:
        return <OrganizationInviteNotification notification={item} />;

      case NotificationType.ORGANIZATION_INVITE_ACCEPTED:
        return <InviteAcceptedNotification notification={item} />;

      case NotificationType.MERCHANT_INVITE_ACCEPTED:
        return <InviteAcceptedNotification notification={item} />;

      default:
        return <p></p>;
    }
  };

  return (
    <>
      <NotificationBell id={id} onClick={handleClick} />
      <Popover
        style={{ marginTop: "1em" }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "200px",
            maxHeight: "500px",
            maxWidth: "380px",
            padding: "1em",
          }}
        >
          {!_.isNil(notification) && notification.notifications.length > 0 ? (
            notification.notifications.map((item) => {
              console.log("the item: ", item);
              return returnNotification(item);
            })
          ) : (
            <p>You don't have any notifications</p>
          )}
        </div>
      </Popover>
    </>
  );
};
