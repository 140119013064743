import axios from "axios";
import { applicationJson, JWT, ROOT_URL } from "../../config/contants";
import axiosInstance from "../interceptors";

export const getAccount = (jwt) => {
  console.log("JWT:", jwt);
  return axiosInstance.get(`${ROOT_URL}api/account`, {
    headers: {
      "Content-Type": applicationJson,
      Authorization: jwt,
    },
  });
};

export const updateAccount = (account) => {
  return axiosInstance.put(`${ROOT_URL}api/account`, account, {
    headers: {
      "Content-Type": applicationJson,
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const resendActivationEmail = async (email) => {
  return axiosInstance.post(`${ROOT_URL}api/resend-activation-email`, email, {
    headers: {
      "Content-Type": applicationJson,
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const sendSMSCode = async (phoneNumber) => {
  return axiosInstance.post(
    `${ROOT_URL}api/send-sms-verification`,
    { phoneNumber: phoneNumber },
    {
      headers: {
        "Content-Type": applicationJson,
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const verifySMSCode = async (code, phoneNumber) => {
  return axiosInstance.post(
    `${ROOT_URL}api/verify-phone-number`,
    { verificationCode: code, phoneNumber: phoneNumber },
    {
      headers: {
        "Content-Type": applicationJson,
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const mfaVerifySMSCode = async (code, phoneNumber) => {
  return axiosInstance.post(
    `${ROOT_URL}api/mfa-verify-sms`,
    { verificationCode: code, phoneNumber: phoneNumber },
    {
      headers: {
        "Content-Type": applicationJson,
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const addSubscriptionAuthority = async (sessionId) => {
  return axiosInstance.post(
    `${ROOT_URL}api/stripe/checkout/add-subscription-authority?session-id=${sessionId}`,
    {},
    {
      headers: {
        "Content-Type": applicationJson,
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const activateAccount = (activationKey) => {
  return axiosInstance.post(`${ROOT_URL}api/activate`, {
    activationKey: activationKey,
  });
};

export const completeAccountSetup = () => {
  return axiosInstance.post(
    `${ROOT_URL}api/complete-account-setup`,
    {},
    {
      headers: {
        "Content-Type": applicationJson,
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const changePassword = (password) => {
  console.log("change password: ", password);
  return axiosInstance.post(
    `${ROOT_URL}api/account/change-password`,
    password,
    {
      headers: {
        "Content-Type": applicationJson,
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const forgotPasswordInit = (email) => {
  return axiosInstance.post(
    `${ROOT_URL}api/account/reset-password/init`,
    email,
    {
      headers: { "Content-Type": "text/plain" },
    }
  );
};

export const forgotPasswordVerify = (verifyResetDTO) => {
  return axiosInstance.post(
    `${ROOT_URL}api/account/reset-password/verify`,
    verifyResetDTO,
    {
      headers: { "Content-Type": applicationJson },
    }
  );
};

export const changeProfilePicture = async (photo) => {
  return axiosInstance.post(`${ROOT_URL}api/account/set-profile-pic`, photo, {
    headers: {
      "Content-Type": "text/plain",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const resetPassword = (resetPasswordData) => {
  return axiosInstance.post(
    `${ROOT_URL}api/account/reset-password/finish`,
    resetPasswordData
  );
};

export const credentialsLogin = (email, password) => {
  console.log(`email: ${email} password: ${password}`);
  return axiosInstance.post(`${ROOT_URL}api/authenticate`, {
    username: email,
    password: password,
  });
};

export const credentialsSignup = (user) => {
  return axiosInstance.post(`${ROOT_URL}api/registerCredentialsUser`, {
    login: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    password: user.password,
    langKey: "en",
  });
};

export const googleSignup = (idToken) => {
  return axiosInstance.post(`${ROOT_URL}api/registerGoogleUser`, {
    idToken: idToken,
  });
};

export const msftSignup = (idToken) => {
  return axiosInstance.post(`${ROOT_URL}api/registerMsftUser`, {
    idToken: idToken,
  });
};

export const logout = (jwt) => {
  return axiosInstance.get(`${ROOT_URL}api/logout`, {
    headers: {
      "Content-Type": applicationJson,
      Authorization: jwt,
    },
  });
};

export const sso = (idToken) => {
  console.log("id token: ", idToken);
  console.log("ROOT_URL: ", ROOT_URL);
  console.log("calling backend...");
  return axiosInstance.post(`${ROOT_URL}api/sso`, {
    idToken: idToken,
  });
};

export const refresh = () => {
  return axiosInstance.post(
    `${ROOT_URL}api/refresh`,
    {},
    {
      withCredentials: true, // Corrected placement of withCredentials option
    }
  );
};
