import React from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { createTheme, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ThemeProvider } from "@emotion/react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { setSelectedOrganization } from "../../models/organization/organizationReducer";
import { getOrganizationAction } from "../../models/organization/organizationActions";

const OrganizationsTable = (props) => {
  const { data, setAddOrganization } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log("got organization data as: ", data);

  const normalized = data.map((curr) => {
    return {
      ...curr,
      address: `${curr.address.city}, ${curr.address.state} ${curr.address.zip}`,
      owner: `${curr.owner.firstName} ${curr.owner.lastName}`,
    };
  });

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableRow: {
          styleOverrides: {
            root: {
              cursor: "pointer",
            },
          },
        },
      },
    });

  const options = {
    filterType: "checkbox",
    download: false, // Set to false to remove the download option
    print: false, // Set to false to remove the print option
    viewColumns: false, // Disable view columns option
    filter: false, // Disable filter table option

    onRowClick: (rowData, rowMeta) => {
      console.log("row data: ", rowData);
      console.log("row meta: ", rowMeta);

      dispatch(getOrganizationAction(data[rowMeta.rowIndex].id));

      navigate(`organization-management/${rowData[0]}}`, {
        organizationName: rowData[0],
      });
    },

    customToolbar: () => {
      return (
        <IconButton
          onClick={() => {
            navigate("register-location");
          }}
          style={{ order: -1 }}
        >
          <AddIcon />
        </IconButton>
      );
    },
    selectableRows: false,
  };

  const columns = [
    {
      name: "name",
      label: "Place Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "owner",
      label: "Owner",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phoneNumber",
      label: "Phone Number",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        style={{ cursor: "pointer" }}
        title={"Business Locations"}
        data={!_.isNil(normalized) && normalized.length != 0 ? normalized : []}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
};

export default OrganizationsTable;
