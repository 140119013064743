import axios from "axios";
import { applicationJson, JWT, ROOT_URL } from "../../config/contants";
import { NotificationType } from "./notificationReducer";
import axiosInstance from "../interceptors";

export const createNotification = async (notification) => {
  return axiosInstance.post(`${ROOT_URL}api/notification`, notification, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getNotifications = async () => {
  return axiosInstance.get(`${ROOT_URL}api/notification`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem(JWT),
    },
  });
};

export const getUnopenedNotificationsCount = async () => {
  return axiosInstance.get(
    `${ROOT_URL}api/notification/unopened-notifications-count`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const openAll = async () => {
  return axiosInstance.post(
    `${ROOT_URL}api/notification/open-all`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const acceptOrganizationInvite = async (id) => {
  return axiosInstance.post(
    `${ROOT_URL}api/organizations/accept-organization-invite/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const acceptMerchantInvite = async (id) => {
  return axiosInstance.post(
    `${ROOT_URL}api/merchants/accept-merchant-invite/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const acceptMerchantInvite2 = async (bankAccountDTO) => {
  return axiosInstance.post(
    `${ROOT_URL}api/merchants/accept-merchant-invite`,
    bankAccountDTO,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const rejectMerchantInvite = async (id) => {
  return axiosInstance.post(
    `${ROOT_URL}api/merchants/reject-merchant-invite/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const rejectOrganizationInvite = async (id) => {
  return axiosInstance.post(
    `${ROOT_URL}api/organizations/reject-organization-invite/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};

export const sendConnectionInvite = async (recipientId) => {
  return axiosInstance.post(
    `${ROOT_URL}api/send-connection-invite?recipientId=${recipientId}`,
    {},
    {
      headers: {
        "Content-Type": applicationJson,
        Authorization: sessionStorage.getItem(JWT),
      },
    }
  );
};
