import React, { useEffect, useState } from "react";

import { AddressWithZip, PhoneNumber } from "../Form/Form";
import _ from "lodash";

const OrganizationDetails = (props) => {
  const { organizationInfo, setOrganizationInfo, control, setValue } = props;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <div
        style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <div style={{ width: isMobile ? "100%" : "75%" }}>
          <AddressWithZip
            disabled={organizationInfo.id ? true : false}
            label={"Business Search"}
            control={control}
            setValue={setValue}
            address={organizationInfo.address}
            setAddress={({
              city,
              state,
              zip,
              addressLine1,
              place,
              formattedPhoneNumber,
              photos,
              name,
            }) => {
              console.log("setting address: ");
              console.log("city: ", city);
              console.log("state: ", state);
              console.log("zip: ", zip);
              console.log("place: ", place);
              console.log("formattedPhoneNumber: ", formattedPhoneNumber);
              console.log("photos: ", photos);

              setValue("Business Phone", formattedPhoneNumber, {
                shouldValidate: true,
              });

              setOrganizationInfo({
                ...organizationInfo,
                formattedPhoneNumber: formattedPhoneNumber,
                phoneNumber: formattedPhoneNumber,
                photos: photos,
                name: name,
                address: {
                  ...organizationInfo.address,
                  addressLine1: place ? addressLine1 : "",
                  city: place ? city : "",
                  state: place ? state : "",
                  zip: place ? zip : "",
                  place: place,
                  placeId: place ? place["place_id"] : "",
                  description: place ? place.description : "",
                },
              });
            }}
          />
        </div>
        <div
          style={{
            width: isMobile ? "100%" : "25%",
          }}
        >
          <PhoneNumber
            disabled={organizationInfo.id ? true : false}
            name="Business Phone"
            control={control}
            phone={organizationInfo?.phoneNumber}
            setPhone={(phone) => {
              setOrganizationInfo({ ...organizationInfo, phoneNumber: phone });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationDetails;
