import React, { useState } from "react";
import moment from "moment";
import {
  Modal,
  Button,
  TextField,
  Paper,
  Card,
  CardActionArea,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { deleteBotThunk } from "../../models/bots/botThunks";
import PhoneNumber from "libphonenumber-js";

import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver"; // Represents voice commands

import EditIcon from "@mui/icons-material/Edit";
import ProtectedComponent from "../ProtectedComponent/ProtectedComponent";
import { ROLE_ADMIN } from "../../config/contants";

const BotCard = (props) => {
  const { bot, handleEditBot } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputBotName, setInputBotName] = useState("");
  const [isConfirmationCorrect, setIsConfirmationCorrect] = useState(true);

  const dispatch = useDispatch();

  const formatPhoneNumber = (phoneNumber) => {
    const parsedPhoneNumber = PhoneNumber(phoneNumber, "US");
    const formattedNumber = parsedPhoneNumber.formatNational();
    const countryCode = parsedPhoneNumber.countryCallingCode;
    return `+${countryCode} ${formattedNumber}`;
  };

  const formatDate = (dateString) => {
    const date = moment(dateString);
    const now = moment();

    if (now.isSame(date, "day")) {
      return `today @ ${date.format("h:mm A")}`;
    } else if (now.isSame(date.clone().add(1, "day"), "day")) {
      return `yesterday @ ${date.format("h:mm A")}`;
    } else {
      return `${date.format("M/D/YYYY @ h:mm A")}`;
    }
  };

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setInputBotName("");
    setIsConfirmationCorrect(true);
  };

  const handleDeleteConfirm = () => {
    if (inputBotName === bot.name && bot.id) {
      // Perform the delete action here
      console.log("Bot deleted");
      handleModalClose();
      dispatch(deleteBotThunk(bot.id));
    } else {
      setIsConfirmationCorrect(false);
    }
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Card
        style={{
          width: "280px",
          maxHeight: "300px",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "1em",
          flexDirection: "column",
          gap: "2em",
        }}
        elevation={1}
      >
        <RecordVoiceOverIcon sx={{ fontSize: "40px", color: "#C0C0C0" }} />

        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
        >
          <h5 style={{ margin: "0px" }}>
            Bot Number: {formatPhoneNumber(bot.claimedPhoneNumber)}
          </h5>
          <h5 style={{ margin: "0px" }}>
            Transfer Number: {formatPhoneNumber(bot.transferPhoneNumber)}
          </h5>
          <h5 style={{ margin: "0px" }}>
            Created: {formatDate(bot.createdDate)}
          </h5>
          <h5 style={{ margin: "0px" }}>
            Last Modified: {formatDate(bot.lastModifiedDate)}
          </h5>
          <div style={{ display: "flex", justifyContent: "row", gap: "1em" }}>
            <ProtectedComponent roles={[ROLE_ADMIN]}>
              <Button
                onClick={handleEditBot}
                variant="contained"
                startIcon={<EditIcon style={{ color: "#1565C0" }} />}
                style={{
                  marginTop: "1em",
                  marginRight: "0.5em",
                  width: "100px",
                  backgroundColor: "#90CAF9", // Subtle blue background
                  color: "white", // White text for better contrast
                  "&:hover": {
                    backgroundColor: "#42A5F5", // Darker blue on hover
                  },
                }}
              >
                Edit
              </Button>
            </ProtectedComponent>
            <ProtectedComponent mustBeOwner roles={[ROLE_ADMIN]}>
              <Button
                onClick={handleDeleteClick}
                variant="contained"
                startIcon={<DeleteIcon style={{ color: "#D32F2F" }} />}
                style={{
                  marginTop: "1em",
                  width: "100px",
                  backgroundColor: "#EF9A9A",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#E57373",
                  },
                }}
              >
                Delete
              </Button>
            </ProtectedComponent>
          </div>
        </div>
      </Card>

      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            padding: "3em",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            rowGap: "1em",
            justifyContent: "flex-start",
          }}
        >
          <h3
            style={{ margin: "0px" }}
          >{`Are you sure you want to delete ${bot.name}?`}</h3>
          <p>Please enter the bot name {bot.name} to confirm</p>
          <TextField
            fullWidth
            label="Bot Name"
            variant="outlined"
            value={inputBotName}
            onChange={(e) => setInputBotName(e.target.value)}
            error={!isConfirmationCorrect}
            helperText={!isConfirmationCorrect ? "Bot name doesn't match" : ""}
          />
          <Button
            onClick={handleDeleteConfirm}
            variant="outlined"
            style={{ marginTop: "1em", width: "100px" }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default BotCard;
