import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { sampleSmsThunk } from "../../models/bots/botThunks";
import TextMessageComponent from "../TextMessageComponent/TextMessageComponent";
import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import { PhoneNumber } from "../Form/Form";
import LinkOptions from "../LinkOptions/LinkOptions";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { defaultSmsContent } from "../BotsModal/BotsModal";

// Modal style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ConfigureSmsComponent = (props) => {
  const { botInfo, setBotInfo } = props;
  const [open, setOpen] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const organizationSlice = useSelector((state) => state.organizations);
  const bot = useSelector((state) => state.bot);
  const ui = useSelector((state) => state.ui);
  const [canClose, setCanClose] = useState(false);

  const { organization } = organizationSlice;

  const { handleSubmit, control } = useForm();

  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const setSmsContent = (smsContent) => {
    setBotInfo((prevState) => ({
      ...prevState,
      smsContent: smsContent,
    }));
  };

  React.useEffect(() => {
    setBotInfo({
      ...botInfo,
      smsContent: {
        ...botInfo.smsContent,
        hours: {
          type: "hours",
          emoji: "⏰",
          label: `${organizationSlice?.organization?.hours}`,
        },
      },
    });
  }, [organizationSlice?.organization?.hours]);

  const handleSendText = () => {
    // Dispatch the action with the phone number
    dispatch(sampleSmsThunk(organization?.id, botInfo.smsContent, phoneNumber));
    // Close the modal
    handleClose();
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <i style={{ marginBottom: "1em" }}>
        Let's configure the SMS customers will recieve. You can edit the emojis
        and the text by clicking on them!
      </i>
      <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
        {/* <LinkOptions /> */}
        <TextMessageComponent
          smsContent={botInfo?.smsContent}
          setSmsContent={setSmsContent}
        />
      </div>

      <div style={{ display: "flex", gap: "10px" }}>
        <Tooltip title="Reset">
          <IconButton
            style={{
              borderRadius: "50%",
              backgroundColor: "black",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#444",
              },
            }}
            onClick={() => {
              setSmsContent(defaultSmsContent);
            }}
          >
            <RotateLeftIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Test SMS">
          <IconButton
            style={{
              borderRadius: "50%",
              backgroundColor: "black",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#444",
              },
            }}
            onClick={handleOpen}
          >
            <SendIcon />
          </IconButton>
        </Tooltip>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          onSubmit={handleSubmit(handleSendText)}
          noValidatecomponent="form"
          sx={modalStyle}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter your phone number
          </Typography>

          <PhoneNumber
            name="Sample SMS Phone Number"
            control={control}
            phone={phoneNumber}
            setPhone={(phone) => {
              setPhoneNumber(phone);
            }}
          />
          <Button
            style={{
              width: "100px",
              backgroundColor: "black", // Dark gray background
              color: "#fff", // White text
              "&:hover": {
                backgroundColor: "#444", // Slightly lighter gray on hover
              },
            }}
            startIcon={<SendIcon />}
            type="submit"
            variant="contained"
            color="primary"
          >
            Send
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfigureSmsComponent;
