import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearRedirection } from "../models/uiReducer";

const useRedirect = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectTo = useSelector((state) => state.ui.redirectTo);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      // If it's the first execution, mark it as not the initial mount anymore
      isInitialMount.current = false;
    } else {
      if (redirectTo) {
        console.log("useRedirect hook: ", redirectTo);
        navigate(redirectTo);
        dispatch(clearRedirection()); // Clear the redirection state after use
      }
    }
  }, [redirectTo, navigate, dispatch]);
};

export default useRedirect;
